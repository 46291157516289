import {
  ICellRendererParams,
  IsRowSelectable,
  RowClassParams,
  RowNode,
  ValueGetterParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Select, { SelectInstance, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { VesselGetResponse } from "src/model/api/response/VesselGetResponse";
import { containsStoredUrls, storeUrl } from "src/util/UrlUtil";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { CompanySuggestionsGetResponse } from "../../model/api/response/CompanySuggestionsGetResponse";
import { OrdersGetResponse } from "../../model/api/response/OrdersGetResponse";
import { UserSuggestionsGetResponse } from "../../model/api/response/UserSuggestionsGetResponse";
import { VesselSuggestionsGetResponse } from "../../model/api/response/VesselSuggestionsGetResponse";
import { agYmdFormatter, defaultColDef } from "../../util/AgGridUtil";
import { ORDER_NO_ALL_ZERO, OrderStatus, TextConst } from "../../util/Constant";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import {
  convertOrderStatusName,
  formatOrderNo,
  isEnglish,
} from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import SearchArea from "../common/SearchArea";
import TooltipIcon from "../common/TooltipIcon";
import OrderDetail, { OrderDetailHandles } from "./OrderDetail";

/** 注文ステータス */
export namespace CompQuoataionStatus {
  /** 未実施 */
  export const NOT_COMP = "0";
  /** 協定依頼中 */
  export const REQ_COMP = "1";
  /** 協定済 */
  export const COMP = "2";
}

/** 注文一覧コンポーネント */
function OrderList() {
  // クエリパラメータ取り出し
  const location = useLocation();
  const locationSearch = location.search;
  const query = new URLSearchParams(locationSearch);
  const queryStatus = query.get("status"); // ステータス
  const queryImono = query.get("imono") ?? undefined; // IMO番号
  const queryCreate = query.get("create") ?? ""; // 新規作成モード
  const queryShow = query.get("show") ?? ""; // 詳細表示モード
  const queryOrderNo = query.get("order-no") ?? ""; // 注文番号
  const queryCompStatus = query.get("comp-status"); // 完工見積ステータス

  // 参照
  const orderUserRef = useRef<SelectInstance<number>>(null); // 発注者コンボ
  const replyUserRef = useRef<SelectInstance<number>>(null); // BEMAC担当者コンボ
  const gridRef = useRef<AgGridReact>(null); // 明細
  const orderDetailRef = useRef<OrderDetailHandles>(null); // 注文詳細

  // ダイアログ使用宣言
  const showDialog = useDialog();
  // API使用宣言
  const api = useApi();
  // ユーザ情報
  const auth = useAuthUserContext();
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("OrderList");

  // state
  // 明細0件時のテキスト
  const noRowsTextTrans = t(TextConst.GRID_INITIAL);
  const [noRowsText, setNoRowsText] = useState(noRowsTextTrans);
  // 検索条件アコーディオン開閉状態
  const [showConditionAccordion, setShowConditionAccordion] = useState(true);
  // ステータス
  const includesStautsInQuery = (status: string) => {
    const statusList = queryStatus?.split(",");
    return statusList?.includes(status) ?? false;
  };
  // 作成中
  const [checkedDraft, setCheckedDraft] = useState(
    includesStautsInQuery(OrderStatus.DRAFT)
  );
  // 注文中
  const [checkedOrdered, setCheckedOrdered] = useState(
    includesStautsInQuery(OrderStatus.ORDERED)
  );
  // 部品手配中
  const [checkedArranging, setCheckedArranging] = useState(
    includesStautsInQuery(OrderStatus.ARRANGING)
  );
  // 否認
  const [checkedRequestedChange, setCheckedRequestedChange] = useState(
    includesStautsInQuery(OrderStatus.REQUESTED_CHANGE)
  );
  // 完了
  const [checkedCompleted, setCheckedCompleted] = useState(
    includesStautsInQuery(OrderStatus.COMPLETED)
  );
  // 取消依頼中
  const [checkedRequestedCancel, setCheckedRequestedCancel] = useState(
    includesStautsInQuery(OrderStatus.REQUESTED_CANCEL)
  );
  // 取消・停止済
  const [checkedStoppedShipping, setCheckedStoppedShipping] = useState(
    includesStautsInQuery(OrderStatus.STOPPED_SHIPPING)
  );

  // 完工見積ステータス
  const includesCompStautsInQuery = (compStatus: string) => {
    const statusList = queryCompStatus?.split(",");
    return statusList?.includes(compStatus) ?? false;
  };
  // 未実施
  const [checkedNotComp, setNotComp] = useState(
    includesCompStautsInQuery(CompQuoataionStatus.NOT_COMP)
  );
  // 協定依頼中
  const [checkedReqComp, setReqComp] = useState(
    includesCompStautsInQuery(CompQuoataionStatus.REQ_COMP)
  );
  // 協定済
  const [checkedComp, setComp] = useState(
    includesCompStautsInQuery(CompQuoataionStatus.COMP)
  );

  // 検索条件（コンボはundefinedが入る）
  type SearchValueType = {
    orderNo: string;
    companyCode: string | undefined;
    imoNo: string | undefined;
    vesselName: string | undefined; // 検索には使用しない。画面遷移による初期値設定に使用。
    orderDateFrom: string;
    orderDateTo: string;
    orderUserId: number | undefined;
    replyUserId: number | undefined;
    checkCompStatus0: boolean;
    checkCompStatus1: boolean;
    checkCompStatus2: boolean;
  };
  const [searchValues, setSearchValues] = useState<SearchValueType>({
    orderNo: formatOrderNo(queryOrderNo),
    companyCode: "",
    imoNo: queryImono,
    vesselName: undefined,
    orderDateFrom: "",
    orderDateTo: "",
    orderUserId: undefined,
    replyUserId: undefined,
    checkCompStatus0: includesCompStautsInQuery(CompQuoataionStatus.NOT_COMP),
    checkCompStatus1: includesCompStautsInQuery(CompQuoataionStatus.REQ_COMP),
    checkCompStatus2: false,
  });

  // 一覧データ
  const [gridData, setGridData] = useState<OrdersGetResponse[]>([]);

  // 発注者（顧客）コンボ
  const [orderUserOptions, setOrderUserOptions] = useState<
    SelectOption<number>[]
  >([]);
  // BEMAC担当者コンボ
  const [replyUserOptions, setReplyUserOptions] = useState<
    SelectOption<number>[]
  >([]);
  // 船名コンボ（船名キー入力ごとにAPI通信）
  const vesselLoadOptions = (inputValue: string) => {
    const companyCode = searchValues.companyCode ?? "";
    const vesselName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/vessel-suggestions?companyCode=${companyCode}&vesselName=${vesselName}`
      )
      .then((it) => {
        return it.data.map((row: VesselSuggestionsGetResponse) => {
          return {
            label: row.shipName,
            value: row.imoNo,
          };
        });
      });
  };

  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/company-suggestions?companyName=${companyName}&searchDelCompanyFlg=true`
      )
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };

  // ステータスリスト
  const statusList = [
    { value: OrderStatus.DRAFT, text: tc("作成中") }, // 顧客のみ表示
    { value: OrderStatus.ORDERED, text: tc("注文中") },
    { value: OrderStatus.ARRANGING, text: tc("部品手配中") },
    { value: OrderStatus.REQUESTED_CHANGE, text: tc("否認") },
    { value: OrderStatus.COMPLETED, text: tc("完了") },
    { value: OrderStatus.REQUESTED_CANCEL, text: tc("取消依頼中") },
    { value: OrderStatus.STOPPED_SHIPPING, text: tc("取消・停止済") },
  ].filter((it) => !auth.user().bemacFlg || it.value !== OrderStatus.DRAFT);

  // 完工見積ステータスリスト
  const comQuotationStatusList = [
    { value: CompQuoataionStatus.NOT_COMP, text: tc("未実施") }, // BEMACのみ表示
    { value: CompQuoataionStatus.REQ_COMP, text: tc("協定依頼中") },
    { value: CompQuoataionStatus.COMP, text: tc("協定済") },
  ].filter(
    (it) => auth.user().bemacFlg || it.value !== CompQuoataionStatus.NOT_COMP
  );

  // 発注者コンボ再設定
  function refreshOrderUserOptions(companyCode: string | undefined) {
    const value = companyCode ?? "";
    // 依頼者コンボ
    api
      .get(`/api/v1/user-suggestions?companyCode=${value}`)
      .then((response) => {
        const users = response.data.map((row: UserSuggestionsGetResponse) => {
          return {
            label: row.userName,
            value: row.userId,
            delFlg: row.delFlg,
          };
        });
        setOrderUserOptions(users);
        // 発注者クリア
        orderUserRef.current?.clearValue();
      });
  }
  // BEMAC担当者コンボ再設定
  function refreshReplyUserOptions(companyCode: string | undefined) {
    const value = companyCode ?? "";
    // BEMAC担当者コンボ（会社選択時絞り込む）
    api
      .get(`/api/v1/user-suggestions?userClass=sales&companyCode=${value}`)
      .then((response) => {
        const users = response.data.map((row: UserSuggestionsGetResponse) => {
          return {
            label: row.userName,
            value: row.userId,
            delFlg: row.delFlg,
          };
        });
        setReplyUserOptions(users);
        // BEMAC担当者クリア
        replyUserRef.current?.clearValue();
      });
  }

  // 検索処理
  function search(afterProc?: (data: OrdersGetResponse[]) => void) {
    const queryParams: string[] = [];
    const orderNoNum = strToNum(searchValues.orderNo);
    if (orderNoNum) {
      queryParams.push(`orderNo=${orderNoNum}`);
    }
    if (searchValues.companyCode) {
      queryParams.push(`companyCode=${searchValues.companyCode}`);
    }
    if (searchValues.imoNo) {
      queryParams.push(`imoNo=${searchValues.imoNo}`);
    }
    if (searchValues.orderDateFrom) {
      queryParams.push(`orderDateFrom=${searchValues.orderDateFrom}`);
    }
    if (searchValues.orderDateTo) {
      queryParams.push(`orderDateTo=${searchValues.orderDateTo}`);
    }
    if (searchValues.orderUserId) {
      queryParams.push(`orderUserId=${searchValues.orderUserId}`);
    }
    if (searchValues.replyUserId) {
      queryParams.push(`replyUserId=${searchValues.replyUserId}`);
    }
    queryParams.push(`checkCompStatus0=${searchValues.checkCompStatus0}`);
    queryParams.push(`checkCompStatus1=${searchValues.checkCompStatus1}`);
    queryParams.push(`checkCompStatus2=${searchValues.checkCompStatus2}`);

    const query = queryParams.join("&");
    api.get<OrdersGetResponse[]>(`/api/v1/orders?${query}`).then((response) => {
      // グリッドテキスト変更
      setNoRowsText(t(TextConst.GRID_NO_DATA));
      // 検索結果をセット
      setGridData(response.data);
      // 検索条件アコーディオンを閉じる
      if (filteredData(response.data).length > 0) {
        setShowConditionAccordion(false);
      }
      // 完工見積ステータスが設定されていたら、ステータスのチェックは全て無効
      if (compStatusChecked()) {
        setCheckedDraft(false); // 作成中
        setCheckedOrdered(false); // 注文中
        setCheckedArranging(false); // 部品手配中
        setCheckedRequestedChange(false); // 否認
        setCheckedCompleted(false); // 完了
        setCheckedRequestedCancel(false); // 取消依頼中
        setCheckedStoppedShipping(false); // 取消・停止済
      }
      // 後処理が指定されていたら実行
      if (afterProc) {
        afterProc(response.data);
      }
    });
  }
  // 明細(grid内)をステータスによってフィルタした結果を取得
  function filteredData(data: OrdersGetResponse[]) {
    const statusAll =
      !checkedDraft &&
      !checkedOrdered &&
      !checkedArranging &&
      !checkedRequestedChange &&
      !checkedCompleted &&
      !checkedRequestedCancel &&
      !checkedStoppedShipping &&
      !checkedNotComp &&
      !checkedReqComp &&
      !checkedComp;
    return data.filter(
      (it) =>
        statusAll ||
        // 作成中
        (checkedDraft && it.statusClassValue === OrderStatus.DRAFT) ||
        // 注文中
        (checkedOrdered && it.statusClassValue === OrderStatus.ORDERED) ||
        // 部品手配中
        (checkedArranging && it.statusClassValue === OrderStatus.ARRANGING) ||
        // 否認
        (checkedRequestedChange &&
          it.statusClassValue === OrderStatus.REQUESTED_CHANGE) ||
        // 完了
        (checkedCompleted && it.statusClassValue === OrderStatus.COMPLETED) ||
        // 取消依頼中
        (checkedRequestedCancel &&
          it.statusClassValue === OrderStatus.REQUESTED_CANCEL) ||
        // 取消・停止済
        (checkedStoppedShipping &&
          (it.statusClassValue === OrderStatus.STOPPED_SHIPPING ||
            it.statusClassValue === OrderStatus.CHANGED ||
            it.statusClassValue === OrderStatus.DENIED_CHANGE)) ||
        // 完工見積 未実施
        (checkedNotComp && it.compStatusName === tc("未実施")) ||
        // 完工見積 協定依頼中
        (checkedReqComp && it.compStatusName === tc("協定依頼中")) ||
        // 完工見積 協定済
        (checkedComp && it.compStatusName === tc("協定済")) ||
        // 完工見積 請求書発行済
        (checkedComp && it.compStatusName === tc("請求書発行済"))
    );
  }

  // 初回レンダリングのみ実行
  useEffect(() => {
    // 発注者コンボ
    refreshOrderUserOptions("");
    // BEMAC担当者コンボ
    refreshReplyUserOptions("");

    // 詳細表示モードの場合は詳細画面を開く
    if (queryShow && queryShow === "true" && queryOrderNo) {
      // ブラウザバックで詳細画面を開かないために検査
      const url = location.pathname + location.search;
      if (!containsStoredUrls(url)) {
        // 検索後処理を定義
        const afterProc = (data: OrdersGetResponse[]) => {
          // 検索結果にクエリパラメータの注文番号がある場合のみ詳細モーダルを開く
          const orderNo = parseInt(queryOrderNo);
          if (data.find((row) => row.orderNo === orderNo)) {
            orderDetailRef.current?.show({ orderNo });
          }
        };
        // 検索を実行
        search(afterProc);
        // ブラウザバックで詳細画面を開かないように記憶させる
        storeUrl(url);
        return;
      }
    }

    //検索
    search();
    // 新規作成モードの場合は詳細画面を開く
    if (queryCreate && queryCreate === "true") {
      orderDetailRef.current?.show({});
    }
    // IMOがクエリパラメータにあれば船名コンボの初期値を設定
    if (queryImono) {
      api
        .get<VesselGetResponse>(`/api/v1/vessels/${queryImono}`)
        .then((response) => {
          setSearchValues({
            ...searchValues,
            imoNo: response.data.imoNo,
            vesselName: response.data.vesselName,
          });
        })
        .catch((error) => {
          showDialog({ error });
        });
    }
  }, []);

  // 検索エリアテキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "orderNo") {
      // 注文番号はフォーマットしてセット
      const orderNo = formatOrderNo(e.target.value);
      // 半角英数字のみを許容
      var reg = new RegExp(/^[0-9]*$/);
      if (reg.test(value ?? "")) {
        setSearchValues({ ...searchValues, orderNo });
      } else {
        setSearchValues({ ...searchValues, [name]: searchValues.orderNo });
      }
    } else {
      // 入力値をセット
      setSearchValues({ ...searchValues, [name]: value });
    }
  }
  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    // 選択値をセット
    const value = e?.value;
    setSearchValues((prev) => {
      return { ...prev, [name]: value };
    });

    // 会社コンボが変更されたら発注者リスト、BEMAC担当者も取り直す
    if (name === "companyCode") {
      refreshOrderUserOptions(value?.toString());
      refreshReplyUserOptions(value?.toString());
    }
    // 船名コンボが変更されたらラベルも保持しておく
    if (name === "imoNo") {
      const vesselName = e?.label;
      setSearchValues((prev) => {
        return { ...prev, vesselName };
      });
    }
  }

  // チェックボックス変更
  function handleChangeCheckBox(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.checked;
    // 入力値をセット
    setSearchValues({ ...searchValues, [name]: value });
  }

  // イベント
  // 検索ボタンクリック
  function onClickSearchButton() {
    search();
  }

  // ステータスボタンクリック
  function handleClickStatusButton(status: string) {
    switch (status) {
      // 作成中
      case OrderStatus.DRAFT:
        setCheckedDraft(!checkedDraft);
        break;
      // 注文中
      case OrderStatus.ORDERED:
        setCheckedOrdered(!checkedOrdered);
        break;
      // 部品手配中
      case OrderStatus.ARRANGING:
        setCheckedArranging(!checkedArranging);
        break;
      // 否認
      case OrderStatus.REQUESTED_CHANGE:
        setCheckedRequestedChange(!checkedRequestedChange);
        break;
      // 完了
      case OrderStatus.COMPLETED:
        setCheckedCompleted(!checkedCompleted);
        break;
      // 取消依頼中
      case OrderStatus.REQUESTED_CANCEL:
        setCheckedRequestedCancel(!checkedRequestedCancel);
        break;
      // 取消・停止済
      case OrderStatus.STOPPED_SHIPPING:
        setCheckedStoppedShipping(!checkedStoppedShipping);
        break;
    }
  }

  // 完工見積チェックボックスクリック
  function handleClickCompCheckBox(compStatus: string) {
    switch (compStatus) {
      // 未実施
      case CompQuoataionStatus.NOT_COMP:
        setNotComp(!checkedNotComp);
        break;
      // 協定依頼中
      case CompQuoataionStatus.REQ_COMP:
        setReqComp(!checkedReqComp);
        break;
      // 協定済
      case CompQuoataionStatus.COMP:
        setComp(!checkedComp);
        break;
    }
  }

  // defaultchecked用
  function isChecked(status: string) {
    switch (status) {
      // 作成中
      case OrderStatus.DRAFT:
        return checkedDraft;
      // 注文中
      case OrderStatus.ORDERED:
        return checkedOrdered;
      // 部品手配中
      case OrderStatus.ARRANGING:
        return checkedArranging;
      // 否認
      case OrderStatus.REQUESTED_CHANGE:
        return checkedRequestedChange;
      // 完了
      case OrderStatus.COMPLETED:
        return checkedCompleted;
      // 取消依頼中
      case OrderStatus.REQUESTED_CANCEL:
        return checkedRequestedCancel;
      // 取消・停止済
      case OrderStatus.STOPPED_SHIPPING:
        return checkedStoppedShipping;
    }
  }

  // 完工見積条件のdefaultchecked用
  function isCompChecked(compStatus: string) {
    switch (compStatus) {
      // 未実施
      case CompQuoataionStatus.NOT_COMP:
        return checkedNotComp;
      // 協定依頼中
      case CompQuoataionStatus.REQ_COMP:
        return checkedReqComp;
      // 協定済
      case CompQuoataionStatus.COMP:
        return checkedComp;
    }
  }

  // 完工見積チェック判定
  function compStatusChecked() {
    return checkedNotComp || checkedReqComp || checkedComp;
  }

  // 注文作成ボタン
  function handleClickCreateOrder() {
    if (queryImono) {
      api
        .get<VesselGetResponse>(`/api/v1/vessels/${queryImono}`)
        .then((response) => {
          orderDetailRef.current?.show({
            initialImoNo: response.data.imoNo,
            initialVesselName: response.data.vesselName,
            initialVesselBuilder: response.data.vesselBuilder,
            initialYardNo: response.data.yardNo,
          });
        })
        .catch((error) => {
          showDialog({ error });
        });
    } else {
      orderDetailRef.current?.show({});
    }
  }

  // 削除ボタンクリック
  function handleClickDeleteButton() {
    // 選択された行を抽出
    const orderNoList = gridRef.current?.api
      .getSelectedRows()
      .map((row) => row.orderNo);

    const count = orderNoList ? orderNoList.length : 0;

    //  1件も選択されてなければエラー
    if (count === 0) {
      showDialog({ id: "E023" });
      return;
    }

    // 確認ダイアログを表示
    showDialog({
      id: "I003",
      args: [count],
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合
          var request = {
            orderNoList,
          };
          // API実行
          api.post(`/api/v1/orders:delete`, request).then((response) => {
            showDialog({
              id: "I036",
              callback() {
                // 再検索
                search();
              },
            });
          });
        }
      },
    });
  }

  // 詳細画面保存ボタンクリック
  function handleClickSaveButton(buttonName: string) {
    // 再検索
    search();
  }

  // 注文番号列レンダラ
  const orderNoCellRenderer = (params: ICellRendererParams) => {
    return (
      <a
        href="#"
        className="text-link"
        onClick={() => orderDetailRef.current?.show({ orderNo: params.value })}
      >
        {formatOrderNo(params.value)}
      </a>
    );
  };

  const getStatusText = (statusClassValue: string) => {
    const statusText = convertOrderStatusName(statusClassValue);
    return isEnglish() ? t("OrderStatus." + statusText) : statusText;
  };

  // グリッドの列定義
  //この画面は顧客とBEMACで項目の並び順が違うのでcolumnDefを２つ利用して切り替える
  // BEMAC用
  const columnDefsBemac = [
    {
      headerName: tc("ステータス"),
      field: "statusClassValue",
      width: isEnglish() ? 170 : 120,
      cellClass: "b-grid-cell-multi-line",
      // ステータス名の表示切り替え
      valueGetter: (params: ValueGetterParams) =>
        getStatusText(params.data.statusClassValue),
    },
    {
      headerName: tc("会社名"),
      field: "companyName",
      width: 150,
    },
    {
      headerName: tc("船名"),
      field: "vesselName",
      width: 150,
    },
    {
      headerName: tc("注文番号"),
      field: "orderNo",
      width: 100,
      cellRenderer: orderNoCellRenderer,
    },
    {
      headerName: tc("発注日"),
      field: "orderDateTime",
      width: 120,
      valueFormatter: agYmdFormatter,
    },
    {
      headerName: tc("BEMAC担当"),
      field: "replyUserName",
      width: 100,
    },
    {
      headerName: tc("完工見積"),
      field: "compStatusName",
      width: isEnglish() ? 110 : 100,
    },
    {
      headerName: tc("PJ番号"),
      field: "r3Wbs",
      width: 120,
    },
    {
      headerName: tc("出荷日"),
      field: "shippingDate",
      width: 140,
      valueFormatter: agYmdFormatter,
    },
    {
      headerName: tc("コメント"),
      field: "r3Comment",
      width: 240,
      sortable: false,
    },
    {
      headerName: tc("種別"),
      field: "orderClassName",
      width: 150,
      cellClass: "b-grid-cell-multi-line",
    },
  ];

  // 顧客用
  const columnDefsCustomer = [
    {
      headerName: "",
      colId: "select",
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: false,
    },
    {
      headerName: tc("ステータス"),
      field: "statusName",
      width: isEnglish() ? 170 : 120,
      cellClass: "b-grid-cell-multi-line",
      // ステータス名の表示切り替え
      valueGetter: (params: ValueGetterParams) =>
        getStatusText(params.data.statusClassValue),
    },
    {
      headerName: tc("注文番号"),
      field: "orderNo",
      width: 100,
      cellRenderer: orderNoCellRenderer,
    },
    {
      headerName: tc("船名"),
      field: "vesselName",
      width: 150,
    },
    {
      headerName: tc("会社名"),
      field: "companyName",
      width: 150,
    },
    {
      headerName: tc("発注日"),
      field: "orderDateTime",
      width: 120,
      valueFormatter: agYmdFormatter,
    },
    {
      headerName: tc("発注者"),
      field: "orderUserName",
      width: 150,
    },
    {
      headerName: tc("完工見積"),
      field: "compStatusName",
      width: 150,
    },
    {
      headerName: tc("種別"),
      field: "orderClassName",
      width: 150,
      cellClass: "b-grid-cell-multi-line",
    },
  ];

  // 選択可能行の定義
  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (rowNode: RowNode) => {
      const selectableStatus = [
        OrderStatus.DRAFT,
        OrderStatus.STOPPED_SHIPPING,
        OrderStatus.CHANGED,
        OrderStatus.DENIED_CHANGE,
      ];
      return rowNode.data
        ? rowNode.data.compStatusName === tc("協定済")
          ? false // 協定済の「変更」は選択不可
          : selectableStatus.includes(rowNode.data.statusClassValue)
          ? true
          : false
        : false;
    };
  }, []);

  // 協定済はグレーアウトする
  function getRowStyle(params: RowClassParams) {
    if (params.data.compStatusName === tc("協定済")) {
      return { backgroundColor: "#b7bdc7" };
    }
    return undefined;
  }

  function strToNum(val: string | undefined) {
    if (!val) return undefined;
    const num = Number(val);
    return num;
  }

  // 検索条件欄のグリッドレイアウト横幅単位
  const colUnit = "col-4 d-flex";
  const colUnitW = auth.user().bemacFlg ? "col-4 d-flex" : "col-6 d-flex";
  const colorderUser = auth.user().bemacFlg ? "col-4 d-flex" : "col-5 d-flex";

  // レンダリング
  return (
    <div className="b-container-list">
      <div style={{ marginBottom: "20px" }}>
        <div className="title-area-style">
          <span>{tc("注文一覧")}</span>
        </div>
        {/* 検索条件定義 */}
        <SearchArea
          show={showConditionAccordion}
          onChangeShow={(value) => {
            setShowConditionAccordion(value);
          }}
        >
          <div className="row">
            <div className={colUnit}>
              <span className="col-form-label px-2 text-nowrap">
                {tc("注文番号")}
              </span>
              <input
                type="text"
                name="orderNo"
                className="form-control"
                placeholder={ORDER_NO_ALL_ZERO}
                value={searchValues.orderNo}
                onChange={handleChangeInput}
                data-cy="注文番号テキスト"
              />
            </div>
            <div className={colUnit}>
              <span className="col-form-label px-2 text-nowrap">
                {tc("船名")}
              </span>
              <span className="flex-grow-1" data-cy="船名コンボ">
                <AsyncSelect
                  styles={defaultSelectStyle}
                  placeholder={TextConst.COMBO_PLACEHOLDER}
                  isClearable
                  cacheOptions
                  defaultOptions
                  key={searchValues.companyCode}
                  loadOptions={vesselLoadOptions}
                  value={
                    searchValues.imoNo
                      ? {
                          value: searchValues.imoNo,
                          label: searchValues.vesselName,
                        }
                      : undefined
                  }
                  onChange={(e) => {
                    handleChangeSelect("imoNo", e);
                  }}
                />
              </span>
            </div>
            {(auth.user().bemacFlg || auth.user().suFlg) && (
              <div className={colUnit}>
                <span className="col-form-label px-2 text-nowrap">
                  {tc("会社名")}
                </span>
                <span className="flex-grow-1" data-cy="得意先名コンボ">
                  <AsyncSelect
                    styles={defaultSelectStyle}
                    placeholder={TextConst.COMBO_PLACEHOLDER}
                    cacheOptions
                    defaultOptions
                    isClearable
                    loadOptions={companyOptions}
                    onChange={(e) => {
                      handleChangeSelect("companyCode", e);
                    }}
                  />
                </span>
              </div>
            )}
          </div>
          <div className="row mt-3">
            <div className={colUnitW}>
              <span className="col-form-label px-2 text-nowrap">
                {tc("発注日")}
              </span>
              <div className="input-group">
                <input
                  type="date"
                  name="orderDateFrom"
                  className="form-control"
                  value={searchValues.orderDateFrom}
                  onChange={handleChangeInput}
                  data-cy="発注日（開始）テキスト"
                />
                <span className="input-group-text">～</span>
                <input
                  type="date"
                  name="orderDateTo"
                  className="form-control"
                  value={searchValues.orderDateTo}
                  onChange={handleChangeInput}
                  data-cy="発注日（終了）テキスト"
                />
              </div>
            </div>
            <div className={colorderUser}>
              <span className="col-form-label px-2 text-nowrap">
                {tc("発注者（顧客）")}
              </span>
              <span className="flex-grow-1" data-cy="発注者コンボ">
                <Select
                  styles={defaultSelectStyle}
                  placeholder={TextConst.COMBO_PLACEHOLDER}
                  isClearable
                  options={orderUserOptions}
                  onChange={(e) => {
                    handleChangeSelect("orderUserId", e);
                  }}
                  ref={orderUserRef}
                />
              </span>
            </div>
            {auth.user().bemacFlg && (
              <div className={colUnit}>
                <span className="col-form-label px-2 text-nowrap">
                  {tc("BEMAC担当")}
                </span>
                <span className="flex-grow-1" data-cy="BEMAC担当者コンボ">
                  <Select
                    styles={defaultSelectStyle}
                    placeholder={TextConst.COMBO_PLACEHOLDER}
                    isClearable
                    options={replyUserOptions}
                    onChange={(e) => {
                      handleChangeSelect("replyUserId", e);
                    }}
                    ref={replyUserRef}
                  />
                </span>
              </div>
            )}
          </div>

          <div className="text-center">
            <input
              type="button"
              className="btn b-btn-primary"
              value={tc("検索")}
              onClick={(e) => onClickSearchButton()}
              data-cy="検索ボタン"
            />
          </div>
        </SearchArea>
        <div
          className="d-flex align-items-center"
          style={{ margin: "0px 30px 10px 30px" }}
        >
          <div>
            {tc("ステータス")}
            <TooltipIcon messageId="T017" />
          </div>
          <span></span>
          <div className="ms-4">
            {statusList.map((it) => {
              return (
                <span key={it.value}>
                  <input
                    id={`checkStatus${it.value}`}
                    type="checkbox"
                    className="form-check-input me-1"
                    defaultChecked={isChecked(it.value)}
                    disabled={compStatusChecked()}
                    onClick={(e) => handleClickStatusButton(it.value)}
                  />
                  <label htmlFor={`checkStatus${it.value}`} className="me-3">
                    {it.text}
                  </label>
                </span>
              );
            })}
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ margin: "0px 30px 10px 30px" }}
        >
          <div>{tc("完工見積")}</div>
          <div className="ms-5">
            {comQuotationStatusList.map((it) => {
              return (
                <span key={it.value}>
                  <input
                    id={`checkCompStatus${it.value}`}
                    name={`checkCompStatus${it.value}`}
                    type="checkbox"
                    className="form-check-input me-1"
                    defaultChecked={isCompChecked(it.value)}
                    onChange={handleChangeCheckBox}
                    onClick={(e) => handleClickCompCheckBox(it.value)}
                  />
                  <label
                    htmlFor={`checkCompStatus${it.value}`}
                    className="me-3"
                  >
                    {it.text}
                  </label>
                </span>
              );
            })}
          </div>
        </div>
        <div
          className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
          style={{ position: "relative", margin: "10px 30px 0px 30px" }}
          data-cy="注文一覧グリッド"
        >
          <AgGridReact
            domLayout="autoHeight"
            defaultColDef={{ sortable: true, ...defaultColDef }}
            columnDefs={
              auth.user().bemacFlg ? columnDefsBemac : columnDefsCustomer
            }
            rowSelection="multiple"
            suppressRowClickSelection
            pagination={true}
            paginationPageSize={20}
            rowData={filteredData(gridData)}
            overlayNoRowsTemplate={noRowsText}
            isRowSelectable={isRowSelectable}
            ref={gridRef}
            getRowStyle={getRowStyle}
          />
          <PageSizeSelector grid={gridRef.current} />
        </div>
        {!auth.user().bemacFlg && (
          <div className="text-center my-2">
            <input
              type="button"
              className="btn b-btn-primary"
              value={tc("注文作成")}
              onClick={handleClickCreateOrder}
              data-cy="注文作成ボタン"
            />
            <input
              type="button"
              className="btn b-btn-delete ms-3"
              value={tc("削除")}
              onClick={handleClickDeleteButton}
              data-cy="削除ボタン"
            />
          </div>
        )}
        <OrderDetail
          ref={orderDetailRef}
          onClickSaveButton={handleClickSaveButton}
        />
      </div>
    </div>
  );
}

export default OrderList;
