import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue, StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { CompanySuggestionsGetResponse } from "../../model/api/response/CompanySuggestionsGetResponse";
import { OrderCostsGetResponse } from "../../model/api/response/OrderCostsGetResponse";
import { ReplacePartsGetResponse } from "../../model/api/response/ReplacePartsGetResponse";
import { VesselSuggestionsGetResponse } from "../../model/api/response/VesselSuggestionsGetResponse";
import { TextConst } from "../../util/Constant";
import {
  SelectOption,
  defaultSelectStyle,
  defaultSelectStyleMulti,
} from "../../util/SelectUtil";
import { getBizError } from "../../util/ecUtil";
import RequireMark from "../common/RequireMark";
import OrderAnalysis from "./OrderAnalysis";
import OrderProductList from "./OrderProductList";

//定数
export const START_YEAR = 1; //表示開始月(1月)
const START_FISCAL_YEAR = 4; //表示開始月(4月)
const SELECT_VESSEL_LIST = 0; //分析対象船舶(船名リストから選択)
const SELECT_ALL_VESSEL = 10; //分析対象船舶(全船)

// 検索条件（コンボはundefinedが入る）タブ表示内用functionでも使用するためexport
export type SearchValueType = {
  /** 得意先コード */
  companyCode: string | undefined;
  /** 選択したIMO */
  imo: string[];
  /** 分析期間（開始） */
  startYear: number | undefined;
  /** 分析期間（終了） */
  endYear: number | undefined;
  /** 表示開始月（1 or 4） */
  showStartMonth: number;
  /** 船名リストから選択 or 全船 */
  analyzedVesselSelect: number | undefined;
  /** 船舶なしの注文を含む */
  vesselNoOrNotContained: boolean;
};

function OrderAnalysisTop() {
  // API使用宣言
  const api = useApi();

  //ダイアログ使用宣言
  const showDialog = useDialog();

  // ユーザ情報
  const auth = useAuthUserContext();

  // 翻訳
  const { t: tc } = useTranslation("OrderAnalysisTop");

  //参照
  const searchAreaRef = useRef<HTMLDivElement>(null); //検索エリア

  //state宣言
  const [data, setData] = useState<ReplacePartsGetResponse[]>([]); //部品交換実施リスト
  const [orderCostDataList, setOrderCostDataList] = useState<
    OrderCostsGetResponse[]
  >([]); //注文金額リスト
  const [selectedOptions, setSelectedOptions] = useState<any>([]); //選択済み船名のリスト
  const [queryParameter, setQueryParameter] = useState(""); //クエリパラメータ
  const [tabName, setTabName] = useState(0); //開いているタブ
  const [orderAnalysisSearchValues, setOrderAnalysisSearchValues] =
    useState<SearchValueType>(initialSearchValues()); //注文分析の前回の検索値
  const [orderProductListSearchValues, setOrderProductListSearchValues] =
    useState<any>(undefined); //部品交換実施リストの前回の検索値

  //検索条件のuseState設定
  const [searchValues, setSearchValues] = useState<SearchValueType>(
    initialSearchValues()
  );
  function initialSearchValues() {
    return {
      companyCode: !auth.user().bemacFlg ? auth.user().companyCode : undefined,
      imo: [],
      startYear: undefined,
      endYear: undefined,
      showStartMonth: START_YEAR,
      analyzedVesselSelect: SELECT_VESSEL_LIST,
      vesselNoOrNotContained: false,
    };
  }
  // 会社名
  const [selectCompanyName, setSelectCompanyName] = useState<
    string | undefined
  >();
  //検索エリアの高さを求める計算式
  const [searchAreaHeight, setSearchAreaHeight] = useState<string | undefined>(
    ""
  );
  //検索エリアの初期の高さ
  const [initialSearchAreaHeight, setInitialSearchAreaHeight] =
    useState<string>("");

  // TabPanelスタイル
  const tabPanelStyle = {
    height: searchAreaHeight,
    width: "100%",
  };

  const analyzeYearSelectStyle: StylesConfig<any | SelectOption<any>, false> = {
    ...defaultSelectStyle,
    container: (provided) => ({
      ...provided,
      flexGrow: "4",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "50px",
    }),
  };

  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(`/api/v1/company-suggestions?companyName=${companyName}`)
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };
  // 初回レンダリングのみ実行
  useEffect(() => {
    setSelectCompanyName(auth.user().companyName);
    setInitialSearchAreaHeight(
      `calc(100vh - (${searchAreaRef.current?.offsetHeight}px + 50px))`
    );
  }, []);

  // 船名コンボ（船名キー入力ごとにAPI通信）
  const vesselLoadOptions = (inputValue: string) => {
    const companyCode = searchValues.companyCode ?? "";
    const vesselName = encodeURIComponent(inputValue);
    setSelectedOptions([]);
    setSearchValues({ ...searchValues, imo: [] });
    if (!searchValues.companyCode) {
      return undefined;
    }
    return api
      .get(
        `/api/v1/vessel-suggestions?companyCode=${companyCode}&vesselName=${vesselName}`
      )
      .then((it) => {
        return it.data.map((row: VesselSuggestionsGetResponse) => {
          return {
            label: row.shipName,
            value: row.imoNo,
          };
        });
      });
  };

  //開始年・終了年の範囲
  function yearScope() {
    let yearArrays: { year: number }[] = [];
    for (let i = 2000; i <= dayjs().year(); i++) {
      let yearArray = { year: i };
      yearArrays.push(yearArray);
    }
    return yearArrays;
  }

  // 開始年コンボ
  const beginYearOptions = yearScope().map((it) => {
    // 入力値をセット
    return { label: it.year.toString(), value: it.year };
  });
  //終了年コンボ
  const endYearOptions = yearScope().map((it) => {
    return { label: it.year.toString(), value: it.year };
  });

  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    // 選択値をセット
    const value = e?.value;
    setSearchValues({ ...searchValues, [name]: value });
    // 得意先コンボボックスの変更の場合は得意先名もセット
    if (name === "companyCode") {
      setSelectCompanyName(e?.label);
    }
    setSearchAreaHeight(initialSearchAreaHeight);
  }

  // 検索エリアコンボ変更(複数選択)
  function handleChangeSelectMulti(
    name: string,
    e: MultiValue<SelectOption<string | number>>
  ) {
    setSelectedOptions(e);
    let selectedList = [];
    for (let i = 0; i < e?.length; i++) {
      selectedList.push(e[i].value);
    }
    setSearchValues({ ...searchValues, [name]: selectedList });
    calcSearchAreaHeight();
  }

  // 表示開始月
  const selectStartMonth = [
    { value: START_YEAR, text: tc("1月(年)") },
    { value: START_FISCAL_YEAR, text: tc("4月(年度)") },
  ];

  // 表示開始月のラジオボタンクリック
  function handleClickStartMonthButton(startMonth: number) {
    setSearchValues({ ...searchValues, showStartMonth: startMonth });
  }

  //分析対象船舶選択
  const selectVessel = [
    { value: SELECT_VESSEL_LIST, text: tc("船名リストから選択") },
    { value: SELECT_ALL_VESSEL, text: tc("全船") },
  ];

  // 分析対象船舶のラジオボタンクリック
  function handleClickSelectVesselButton(analyzedVessel: number) {
    if (analyzedVessel === SELECT_ALL_VESSEL) {
      // 全船が選択されたら船名リストをクリアする
      setSelectedOptions([]);
      setSearchValues({
        ...searchValues,
        imo: [],
        analyzedVesselSelect: SELECT_ALL_VESSEL,
      });
    } else {
      setSearchValues({
        ...searchValues,
        analyzedVesselSelect: SELECT_VESSEL_LIST,
      });
    }
    calcSearchAreaHeight();
  }

  // スタイル
  function statusButtonStyle(status: number) {
    // 選択中のステータスと一致していたらオレンジにする
    if (
      status === searchValues.showStartMonth ||
      status === searchValues.analyzedVesselSelect
    ) {
      return { backgroundColor: "#FD6B00", color: "white" };
    } else {
      return { backgroundColor: "white", color: "black" };
    }
  }

  // 船舶なし含有有無チェックボックスの状態
  function includeNoVesselOrderCheck(status: boolean) {
    if (status === false) {
      setSearchValues({ ...searchValues, vesselNoOrNotContained: true });
    } else {
      setSearchValues({ ...searchValues, vesselNoOrNotContained: false });
    }
  }

  //検索ボタンクリック時の処理
  function onClickSearchButton() {
    search();
  }

  // 検索処理
  function search(tabIndex?: number) {
    let currentTabName = tabIndex ?? tabName;
    // API側でチェックできない項目
    if (!searchValues.companyCode && !auth.user().bemacFlg) {
      showDialog({ id: "E068" });
      return;
    }

    if (
      searchValues.analyzedVesselSelect === SELECT_VESSEL_LIST &&
      !searchValues.imo.length &&
      searchValues.vesselNoOrNotContained === false
    ) {
      showDialog({ id: "E067" });
      return;
    }

    const queryParams: string[] = [];
    if (searchValues.startYear) {
      queryParams.push(`startYear=${searchValues.startYear}`);
    } else {
      queryParams.push(`startYear=`);
    }
    if (searchValues.endYear) {
      queryParams.push(`endYear=${searchValues.endYear}`);
    } else {
      queryParams.push(`endYear=`);
    }
    if (searchValues.showStartMonth) {
      queryParams.push(`showStartMonth=${searchValues.showStartMonth}`);
    } else {
      queryParams.push(`showStartMonth=`);
    }
    queryParams.push(
      `analyzedVesselSelect=${searchValues.analyzedVesselSelect}`
    );
    for (let j = 0; j < searchValues.imo.length; j++) {
      queryParams.push(`vesselList=${searchValues.imo[j]}`);
    }
    queryParams.push(
      `vesselNoOrNotContained=${searchValues.vesselNoOrNotContained}`
    );
    const query = queryParams.join("&");

    if (currentTabName === 0) {
      //注文分析が選択されている場合
      api
        .get(
          `/api/v1/companies/${
            !searchValues.companyCode ? -1 : searchValues.companyCode
          }/order-costs?${query}`
        )
        .then((response) => {
          // 検索結果をセット
          setOrderCostDataList(response.data);
          setOrderAnalysisSearchValues(searchValues);
        })
        .catch((error) => {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E073" });
          }
        });
    }
    if (currentTabName === 1) {
      //部品交換実施リストが選択されている場合
      api
        .get(
          `/api/v1/companies/${
            !searchValues.companyCode ? -1 : searchValues.companyCode
          }/replace-parts?${query}`
        )
        .then((response) => {
          // 検索結果をセット
          setData(response.data);
          setOrderProductListSearchValues(searchValues);
          //クエリパラメータ設定用にコピー
          setQueryParameter(query);
        })
        .catch((error) => {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E073" });
          }
        });
    }
  }

  //どちらのタブが開いているかチェックする
  const initState = (tabName: any) => {
    setTabName(tabName);

    //pageSizeSelectorの都合上、前回の値で再検索する
    if (tabName === 1 && orderProductListSearchValues !== undefined) {
      //部品交換実施リストが選択されている場合
      api
        .get(
          `/api/v1/companies/${orderProductListSearchValues.companyCode}/replace-parts?${queryParameter}`
        )
        .then((response) => {
          // 検索結果をセット
          setData(response.data);
        });
    }
  };

  //検索エリアの高さを計算する
  function calcSearchAreaHeight() {
    setTimeout(() => {
      setSearchAreaHeight(
        `calc(100vh - (${searchAreaRef.current?.offsetHeight}px + 50px))`
      );
    }, 0);
  }

  //レンダリング
  return (
    <>
      <div className="title-area-style">
        <span>{tc("分析")}</span>
      </div>
      <div
        style={{
          width: "95%",
          margin: "0 auto",
        }}
      >
        <div ref={searchAreaRef}>
          <div className="my-3">
            <div className="row">
              {(auth.user().bemacFlg || auth.user().suFlg) && (
                <div className="col">
                  <div className="input-group">
                    <span className="b-input-group-text">
                      {tc("得意先名")}
                      {!auth.user().bemacFlg && <RequireMark />}
                    </span>
                    <span className="form-control" data-cy="得意先名コンボ">
                      <AsyncSelect
                        value={
                          !searchValues.companyCode
                            ? null
                            : {
                                value: searchValues.companyCode,
                                label: selectCompanyName,
                              }
                        }
                        styles={defaultSelectStyle}
                        placeholder={TextConst.COMBO_PLACEHOLDER}
                        isClearable
                        cacheOptions
                        defaultOptions
                        loadOptions={companyOptions}
                        onChange={(e) => {
                          handleChangeSelect("companyCode", e);
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
              <div className="col-auto">
                <div className="input-group">
                  <span className="b-input-group-text ">
                    {tc("分析期間")}
                    <RequireMark />
                  </span>
                  <div className="d-flex flex-grow-1">
                    <span data-cy="分析開始コンボ" className="flex-grow-1">
                      <Select
                        styles={analyzeYearSelectStyle}
                        placeholder="yyyy"
                        isClearable
                        options={beginYearOptions}
                        onChange={(e) => {
                          handleChangeSelect("startYear", e);
                        }}
                      />
                    </span>
                    <span className="input-group-text">~</span>
                    <span data-cy="分析終了コンボ" className="flex-grow-1">
                      <Select
                        styles={analyzeYearSelectStyle}
                        placeholder="yyyy"
                        isClearable
                        options={endYearOptions}
                        onChange={(e) => {
                          handleChangeSelect("endYear", e);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="input-group">
                  <span className="b-input-group-text">
                    {tc("表示開始月")}
                    <RequireMark />
                  </span>
                  <span className="form-control d-flex align-middle">
                    {selectStartMonth.map((it) => {
                      return (
                        <input
                          key={it.value}
                          type="button"
                          className="btn rounded-pill me-2"
                          style={statusButtonStyle(it.value)}
                          value={it.text}
                          onClick={(e) => handleClickStartMonthButton(it.value)}
                          data-cy={`${it.text}ボタン`}
                        />
                      );
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <span className="b-input-group-text" style={{ width: "10em" }}>
              {tc("分析対象船舶")}
              <RequireMark />
            </span>
            <div className="form-control">
              <div className="mb-4">
                {selectVessel.map((it) => {
                  return (
                    <input
                      key={it.value}
                      type="button"
                      className="btn rounded-pill me-2"
                      style={statusButtonStyle(it.value)}
                      value={it.text}
                      onClick={(e) => handleClickSelectVesselButton(it.value)}
                      data-cy={`${it.text}ボタン`}
                    />
                  );
                })}
                <span style={{ color: "red" }}>
                  {tc("※全船選択時は、合計額を表示します。")}
                </span>
              </div>
              <div className="row">
                <div className="col-auto">
                  {tc("船名リスト")}
                  <br />({tc("最大10隻")})
                </div>
                <div className="col-9" data-cy="船名リストコンボ">
                  <AsyncSelect
                    className="basic-multi-select"
                    styles={defaultSelectStyleMulti}
                    isMulti
                    placeholder={TextConst.COMBO_PLACEHOLDER}
                    isClearable
                    cacheOptions
                    defaultOptions
                    key={searchValues.companyCode}
                    loadOptions={vesselLoadOptions}
                    value={selectedOptions}
                    closeMenuOnSelect={false}
                    isDisabled={
                      searchValues.analyzedVesselSelect === SELECT_VESSEL_LIST
                        ? false
                        : true
                    }
                    isOptionDisabled={() => selectedOptions.length >= 10}
                    onChange={(e) => {
                      handleChangeSelectMulti("imo", e);
                    }}
                  />
                  <input
                    id="includeNoVesselOrderCheck"
                    className="form-check-input"
                    type="checkbox"
                    disabled={
                      searchValues.analyzedVesselSelect === SELECT_VESSEL_LIST
                        ? false
                        : true
                    }
                    onChange={(e) => {
                      includeNoVesselOrderCheck(
                        searchValues.vesselNoOrNotContained
                      );
                    }}
                    data-cy="船舶無し含有有無チェックボックス"
                  />
                  <label className="ms-2" htmlFor="includeNoVesselOrderCheck">
                    {tc("船舶なしの注文を含む")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn b-btn-primary mt-2 mx-auto"
              onClick={onClickSearchButton}
              data-cy="検索ボタン"
            >
              {tc("検索")}
            </button>
          </div>
        </div>
        <div>
          <Tabs onSelect={initState}>
            <TabList>
              <Tab data-cy="注文分析タブ">{tc("注文分析")}</Tab>
              <Tab data-cy="部品交換実施リストタブ">
                {tc("部品交換実施リスト")}
              </Tab>
            </TabList>
            <TabPanel style={tabPanelStyle} data-cy="注文分析タブパネル">
              <OrderAnalysis
                searchValues={orderAnalysisSearchValues!}
                orderCostDataList={orderCostDataList}
              />
            </TabPanel>
            <TabPanel
              style={tabPanelStyle}
              data-cy="部品交換実施リストタブパネル"
            >
              {OrderProductList(
                data,
                orderProductListSearchValues,
                queryParameter
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default OrderAnalysisTop;
