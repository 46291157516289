import {
  ColDef,
  HeaderValueGetterParams,
  ICellRendererParams,
  SuppressKeyboardEventParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue } from "react-select";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useS3 } from "../../hook/useS3";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { CompanyGetResponse } from "../../model/api/response/CompanyGetResponse";
import { InspectionResultPicturesGetResponse } from "../../model/api/response/InspectionResultPicturesGetResponse";
import { InspectionResultsGetResponse } from "../../model/api/response/InspectionResultsGetResponse";
import { MaintenanceSchedulesGetResponse } from "../../model/api/response/MaintenanceSchedulesGetResponse";
import { UserSuggestionsGetResponse } from "../../model/api/response/UserSuggestionsGetResponse";
import { VesselGetResponse } from "../../model/api/response/VesselGetResponse";
import { defaultColDef } from "../../util/AgGridUtil";
import { TextConst } from "../../util/Constant";
import { SelectOption, defaultSelectStyleMulti } from "../../util/SelectUtil";
import { formatDateBetween, getBizError } from "../../util/ecUtil";
import { TIMINGS, TIMING_MAIN, TIMING_SUBS } from "../../util/lcm/LcmConst";
import InspectionResultPicture, {
  InspectionResultPictureHandles,
} from "./InspectionResultPicture";

// 写真リストの型
export type PictureListType = {
  companyCode: string;
  imoNo: string;
  inspectionYearClassValue: string;
  pictureList: File;
};

// 添付ファイルの型
type FileType = {
  fileObject: File;
  uploadFlg: boolean;
  deleteFlg: boolean;
};

// サービスレポートリストの型
type ServiceReportListType = {
  companyCode: string;
  imoNo: string;
  inspectionYearClassValue: string;
  serviceReportList: FileType[];
};

type Props = {
  companyCode: string | undefined;
  imoNo: string | undefined;
  setFormChanged: (state: boolean) => void;
  yearCheckValue: number;
};

type InspectionResultDataType = {
  companyCode: string;
  imoNo: string;
  timing: number;
  engineerId1?: string;
  engineerName1?: string;
  engineerId2?: string;
  engineerName2?: string;
  engineerId3?: string;
  engineerName3?: string;
  engineerComment?: string;
  inspectionResultFlg?: boolean;
  updateDateTime?: string;
};

type saveButtonName = "一時保存" | "登録";

enum RowTypes {
  NextDockName,
  NextDockPeriod,
  ServiceReport,
  PictureList,
  Engineer,
  EngineerComment,
  Footer,
}

function InspectionResult(props: Props) {
  // API使用宣言
  const api = useApi();

  // 権限情報
  const auth = useAuthUserContext();

  // S3使用宣言
  const s3 = useS3();

  // ダイアログ使用宣言
  const showDialog = useDialog();

  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("InspectionResult");

  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  //ユーザー情報
  const user = useAuthUserContext().user();

  // 参照
  const inspectionResultPictureRef =
    useRef<InspectionResultPictureHandles>(null); // 定期点検写真

  // 技師コンボ
  const [engineerOptions, setEngineerOptions] = useState<
    SelectOption<string>[]
  >([]);

  // 竣工年
  const [completedYear, setCompletedYear] = useState<number>(0);

  // IMO
  const [imoNo, setImoNo] = useState<string>();

  // 表示年度切替
  const [yearCheckValue, setYearCheckValue] = useState<number>();

  // 定期点検結果グリッド0件時のテキスト
  const SELECT_VESSEL = tc("対象船舶を選択してください。");
  const [noRowsInspectionResult, setNoRowsInspectionResult] =
    useState(SELECT_VESSEL);

  // 折り返しスタイル
  const wrapStyle: React.CSSProperties = {
    lineHeight: "normal",
    whiteSpace: "normal",
  };

  // 表示用定期点検結果リスト
  const [inspecitionResultData, setInspecitionResultData] = useState<
    InspectionResultDataType[]
  >([]);

  // 表示用メンテナンススケジュールリスト
  const [maintenaceScheduleDataList, setMaintenanceScheduleDataList] = useState<
    MaintenanceSchedulesGetResponse[]
  >([]);

  // 表示用サービスレポートリスト
  const [serviceReportList, setServiceReportList] = useState<
    ServiceReportListType[]
  >([]);

  // 表示用写真データリスト
  const [pictureDataList, setPictureDataList] = useState<PictureListType[]>([]);

  // 表示用グリッドデータ
  const [gridData, setGridData] = useState<GridType[] | null>([]);

  // 船舶情報
  const [vessel, setVessel] = useState<VesselGetResponse>();

  // 得意先情報
  const [company, setCompany] = useState<CompanyGetResponse>();

  type GridType = {
    rowType: RowTypes;
    rowTitle: string;
  };

  type SelectedType = {
    label: string;
    value: string;
  };

  // 画像ファイル判定
  function isImageFile(path: string) {
    const imageExtensions = [".png", ".jpg", ".jpeg", ".gif"];
    const x = imageExtensions.filter((extenstion) => path.endsWith(extenstion));
    return x.length > 0;
  }

  // PDFファイル判定
  function isPdfFile(path: string) {
    return path.endsWith(".pdf");
  }

  // サービスレポートファイル選択処理
  function selectFile(
    e: React.ChangeEvent<HTMLInputElement>,
    timing: number,
    dataRow: InspectionResultDataType
  ) {
    // 取得したファイルを対象のグリッド用データに設定する。
    const newFileList: FileType[] = [];
    if (e.currentTarget.files) {
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        newFileList.push({
          fileObject: e.currentTarget.files![i],
          uploadFlg: false,
          deleteFlg: false,
        });
      }
    }

    let updateServiceReportData: ServiceReportListType[];
    if (
      newFileList.length > 0 &&
      serviceReportList.findIndex(
        (it) => Number(it.inspectionYearClassValue) === timing
      ) > -1
    ) {
      updateServiceReportData = serviceReportList.map((i) => {
        if (Number(i.inspectionYearClassValue) === timing) {
          if (
            i.serviceReportList &&
            (
              i.serviceReportList.filter((file) => file.deleteFlg === false) ??
              []
            ).length +
              newFileList.length >
              10
          ) {
            // サービスレポートの合計が10を超える場合、選択したファイルの一部を削除
            newFileList.splice(
              newFileList.length -
                ((
                  i.serviceReportList.filter(
                    (file) => file.deleteFlg === false
                  ) ?? []
                ).length +
                  newFileList.length -
                  10),
              (
                i.serviceReportList.filter(
                  (file) => file.deleteFlg === false
                ) ?? []
              ).length +
                newFileList.length -
                10
            );
          }
          return {
            ...i,
            serviceReportList: [...(i.serviceReportList ?? []), ...newFileList],
          };
        } else {
          return i;
        }
      });
    } else {
      if (newFileList.length > 10) {
        // サービスレポートの合計が10を超える場合、選択したファイルの一部を削除
        newFileList.splice(newFileList.length - 10, newFileList.length - 10);
      }
      updateServiceReportData = [
        {
          companyCode: dataRow.companyCode,
          imoNo: dataRow.imoNo,
          inspectionYearClassValue: timing.toString(),
          serviceReportList: newFileList,
        },
      ];
    }

    setServiceReportList(updateServiceReportData);
    props.setFormChanged(true);
  }

  // サービスレポートファイル削除処理
  function deleteFile(timing: number, fileIndex: number) {
    // 削除したファイルリストを対象のグリッド用データに設定する
    const updateInspecitionResultData = serviceReportList.map((i) => {
      if (Number(i.inspectionYearClassValue) === Number(timing)) {
        i.serviceReportList[fileIndex].deleteFlg = true;
      }
      return i;
    });
    setServiceReportList(updateInspecitionResultData);
    props.setFormChanged(true);
  }

  // 技師コメント変更時処理
  function handleChangeComment(
    e: ChangeEvent<HTMLTextAreaElement>,
    timing: number
  ) {
    // 選択値をセット
    const value = e?.target.value;

    // グリッドデータの値を修正
    const updateInspecitionResultData = inspecitionResultData.map((i) => {
      if (i.timing === Number(timing)) {
        i.engineerComment = value;
      }
      return i;
    });
    setInspecitionResultData(updateInspecitionResultData);

    props.setFormChanged(true);
  }

  // 担当技師コンボ変更
  function handleChangeEngineer(
    e: MultiValue<SelectOption<string | number>>,
    timing: number
  ) {
    // 選択値をセット
    let selectedList: SelectedType[] = [];
    for (let i = 0; i < e?.length; i++) {
      selectedList.push({
        label: e[i].label,
        value: e[i].value,
      } as SelectedType);
    }

    const updateInspecitionResultData = inspecitionResultData.map((i) => {
      if (i.timing === Number(timing)) {
        i.engineerId1 = undefined;
        i.engineerId2 = undefined;
        i.engineerId3 = undefined;
        i.engineerName1 = undefined;
        i.engineerName2 = undefined;
        i.engineerName3 = undefined;

        if (selectedList.length > 0) {
          i.engineerId1 = selectedList[0].value;
          i.engineerName1 = selectedList[0].label;
        }

        if (selectedList.length > 1) {
          i.engineerId2 = selectedList[1].value;
          i.engineerName2 = selectedList[1].label;
        }

        if (selectedList.length > 2) {
          i.engineerId3 = selectedList[2].value;
          i.engineerName3 = selectedList[2].label;
        }
      }
      return i;
    });

    setInspecitionResultData(updateInspecitionResultData);
    props.setFormChanged(true);
  }

  // グリッドのセルレンダラー
  function cellRenderSelector(params: ICellRendererParams) {
    switch (params.data.rowType) {
      case RowTypes.NextDockName:
        return { component: nextDockNameCell };
      case RowTypes.NextDockPeriod:
        return { component: nextDockPeriodCell };
      case RowTypes.Engineer:
        return { component: engineerCell };
      case RowTypes.EngineerComment:
        return { component: engineerCommentCell };
      case RowTypes.ServiceReport:
        return { component: serviceReportCell };
      case RowTypes.PictureList:
        return { component: pictureListCell };
      case RowTypes.Footer:
        return { component: footerCell };
      default:
        return undefined;
    }
  }

  function cellHeaderRenderSelector(params: ICellRendererParams) {
    switch (params.data.rowType) {
      case RowTypes.ServiceReport:
        return { component: serviceReportCellHeader };
      default:
        return params.data.rowTitle;
    }
  }

  function getDataRow(params: ICellRendererParams) {
    const timingString = params.column?.getColId().replace("col", "") ?? "0";
    return inspecitionResultData.find(
      (it) => it.timing.toString() === timingString
    )!;
  }

  function nextDockNameCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    // 定期点検結果に紐づくメンテナンススケジュールを取得
    const maintenanceScheduleData = maintenaceScheduleDataList.find(
      (it) => Number(it.inspectionYearClassValue) === dataRow.timing
    );

    return (
      <>
        <span data-cy="次回入渠ドック">
          {maintenanceScheduleData?.dockName}
        </span>
      </>
    );
  }
  function nextDockPeriodCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    // 定期点検結果に紐づくメンテナンススケジュールを取得
    const maintenanceScheduleData = maintenaceScheduleDataList.find(
      (it) => Number(it.inspectionYearClassValue) === dataRow.timing
    );
    return (
      <>
        <span data-cy="次回入渠ドック予定">
          {formatDateBetween(
            maintenanceScheduleData?.dockScheduleStart,
            maintenanceScheduleData?.dockScheduleEnd
          )}
        </span>
      </>
    );
  }
  function engineerCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    const defaultEngineers = [
      { value: dataRow.engineerId1, label: dataRow.engineerName1 },
      { value: dataRow.engineerId2, label: dataRow.engineerName2 },
      { value: dataRow.engineerId3, label: dataRow.engineerName3 },
    ];

    const values = defaultEngineers.filter((it) => it.value);

    return (
      <>
        {auth.isEngineer() && (
          <div data-cy="担当技師コンボ">
            <Select
              value={values}
              isMulti
              options={engineerOptions}
              styles={defaultSelectStyleMulti}
              menuPortalTarget={document.body}
              isOptionDisabled={() => values.length >= 3}
              className="my-2"
              onChange={(e) => {
                // OnChangeイベントだと1文字入力毎にフォーカス外れるので、onBlurで発火させる(グリッドの内のテキストボックスだけっぽい？)
                handleChangeEngineer(e, dataRow.timing);
              }}
            />
          </div>
        )}

        {!auth.isEngineer() && (
          <div className="my-2" style={wrapStyle} data-cy="担当技師">
            {values.map((it) => {
              return <div key={it.value}>{it.label}</div>;
            })}
          </div>
        )}
      </>
    );
  }
  function engineerCommentCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    return (
      <>
        {auth.isEngineer() && (
          <textarea
            className="form-control my-2 textarea"
            style={{ height: "100px" }}
            defaultValue={dataRow.engineerComment}
            onBlur={(e) => {
              // OnChangeイベントだと1文字入力毎にフォーカス外れるので、onBlurで発火させる(グリッドの内のテキストボックスだけっぽい？)
              handleChangeComment(e, dataRow.timing);
            }}
            data-cy="技師コメントテキスト"
          />
        )}
        {!auth.isEngineer() && (
          <label style={wrapStyle} data-cy="技師コメント">
            {dataRow.engineerComment}
          </label>
        )}
      </>
    );
  }
  function serviceReportCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    const timingString = params.column?.getColId().replace("col", "") ?? "0";
    const serviceReportData = serviceReportList.find(
      (it) => Number(it.inspectionYearClassValue) === dataRow.timing
    );

    return (
      <>
        {auth.isEngineer() && (
          <>
            <input
              type="file"
              id={dataRow.timing + "_file"}
              accept=".pdf"
              style={{ display: "none" }}
              onChange={(e) => selectFile(e, Number(timingString), dataRow)}
              onClick={(e: any) => {
                e.target.value = "";
              }}
              multiple={true}
              data-cy="サービスレポート選択"
            />
            <input
              type="button"
              value={tc("レポート添付")}
              style={{ fontSize: "0.8rem" }}
              className="btn b-btn-primary"
              onClick={() =>
                document.getElementById(dataRow.timing + "_file")?.click()
              }
              data-cy="サービスレポート添付ボタン"
            />
            <div data-cy="サービスレポート" style={{ minHeight: "50px" }}>
              {serviceReportData &&
                serviceReportData.serviceReportList
                  .filter((it) => it.deleteFlg === false)
                  .map((file: FileType) => {
                    const onClick = () => {
                      if (
                        isImageFile(file.fileObject.name) ||
                        isPdfFile(file.fileObject.name)
                      ) {
                        // 画像・PDFは別ウィンドウで開く
                        const blobUrl = URL.createObjectURL(file.fileObject);
                        window.open(blobUrl);
                      } else {
                        // その他はダウンロード
                        const a = document.createElement("a");
                        a.download = file.fileObject.name;
                        a.href = URL.createObjectURL(file.fileObject);
                        a.click();
                        a.remove();
                      }
                    };
                    return (
                      <div key={file.fileObject.name} style={wrapStyle}>
                        <a
                          key={file.fileObject.name}
                          style={{ position: "relative" }}
                          href="#"
                          onClick={onClick}
                          data-cy="サービスレポートリンク"
                        >
                          {file.fileObject.name}
                        </a>
                        <a
                          className="ms-2"
                          href="#"
                          onClick={() =>
                            deleteFile(
                              Number(timingString),
                              serviceReportData.serviceReportList.indexOf(file)
                            )
                          }
                          data-cy="サービスレポート削除"
                        >
                          ({tc("削除")})
                        </a>
                      </div>
                    );
                  })}
            </div>
          </>
        )}
        {!auth.isEngineer() && (
          <>
            <div data-cy="サービスレポート">
              {serviceReportData &&
                serviceReportData.serviceReportList
                  .filter((it) => it.deleteFlg === false)
                  .map((file: FileType) => {
                    const onClick = () => {
                      if (
                        isImageFile(file.fileObject.name) ||
                        isPdfFile(file.fileObject.name)
                      ) {
                        // 画像・PDFは別ウィンドウで開く
                        const blobUrl = URL.createObjectURL(file.fileObject);
                        window.open(blobUrl);
                      } else {
                        // その他はダウンロード
                        const a = document.createElement("a");
                        a.download = file.fileObject.name;
                        a.href = URL.createObjectURL(file.fileObject);
                        a.click();
                        a.remove();
                      }
                    };
                    return (
                      <div key={file.fileObject.name} style={wrapStyle}>
                        <a
                          href="#"
                          onClick={onClick}
                          data-cy="サービスレポートリンク"
                        >
                          {file.fileObject.name}
                        </a>
                      </div>
                    );
                  })}
            </div>
          </>
        )}
      </>
    );
  }

  function pictureListCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    const pictureData = pictureDataList.find(
      (it) => Number(it.inspectionYearClassValue) === dataRow.timing
    );

    let blobURL: string = "";
    if (pictureData?.pictureList) {
      blobURL = URL.createObjectURL(pictureData?.pictureList);
    }

    return (
      <>
        <input
          type="button"
          value={
            auth.isEngineer() ? tc("写真の登録") : tc("その他の写真を見る")
          }
          style={{ fontSize: "0.8rem" }}
          className="btn b-btn-primary"
          onClick={(e) =>
            inspectionResultPictureRef.current?.show(
              company,
              vessel,
              dataRow.companyCode,
              dataRow.imoNo,
              dataRow.timing,
              props.yearCheckValue
            )
          }
          data-cy="写真ボタン"
        />
        <img src={blobURL} className="my-2 w-100" alt="" data-cy="写真" />
      </>
    );
  }
  function footerCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    return (
      <>
        <div>
          <input
            type="button"
            value={tc("一時保存")}
            className="btn b-btn-light"
            disabled={dataRow.inspectionResultFlg}
            style={{ fontSize: "0.8rem", minWidth: "80px" }}
            onClick={(e) => handleClickActionButton("一時保存", dataRow.timing)}
            data-cy="一時保存ボタン"
          />
          <input
            type="button"
            value={tc("登録")}
            className="btn b-btn-primary ms-2"
            style={{ fontSize: "0.8rem", minWidth: "80px" }}
            onClick={(e) => handleClickActionButton("登録", dataRow.timing)}
            data-cy="登録ボタン"
          />
        </div>
      </>
    );
  }

  function setPirctureData(pictureData: PictureListType[]) {
    setPictureDataList(pictureData);
  }

  function serviceReportCellHeader(params: ICellRendererParams) {
    return (
      <>
        {params.data.rowTitle}
        {auth.isEngineer() && (
          <div
            style={{
              ...wrapStyle,
              color: "red",
            }}
            data-cy="サービスレポートコメント"
          >
            {tc("※ファイル添付は、各年度最大10件までです。")}
          </div>
        )}
      </>
    );
  }

  function getHeaderTitle(params: HeaderValueGetterParams) {
    const timingString = params.column?.getColId()?.replace("col", "") ?? "0";
    const dataRow = inspecitionResultData.find(
      (it) => it.timing.toString() === timingString
    )!;
    return `${
      yearCheckValue === 0 && TIMING_SUBS.includes(dataRow.timing)
        ? dataRow.timing + 0.5
        : dataRow.timing
    }${tc("年(") + (completedYear + dataRow.timing) + tc("年)")}`;
  }
  // グリッドの列定義
  const columnDefs: ColDef[] = [
    {
      headerName: "",
      field: "rowTitle",
      pinned: true,
      colId: "header",
      cellRendererSelector: cellHeaderRenderSelector,
    },
  ];
  inspecitionResultData.forEach((it) => {
    columnDefs.push({
      headerValueGetter: getHeaderTitle,
      cellRendererSelector: cellRenderSelector,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams<any>) => true,
      colId: "col" + it.timing,
      width: 300,
      wrapText: true,
      autoHeight: true,
    });
  });

  // 定期点検データリスト生成
  function getInsepectionResultDataList(
    inspectionResultList: InspectionResultsGetResponse[]
  ) {
    const inspectionResultDataList = inspectionResultList
      .sort(
        (a, b) =>
          Number(a.inspectionYearClassValue) -
          Number(b.inspectionYearClassValue)
      )
      .map((row: InspectionResultsGetResponse) => {
        return {
          companyCode: row.companyCode,
          imoNo: row.imoNo,
          timing: Number(row.inspectionYearClassValue),
          engineerId1:
            row.engineerUserId1 !== -1 ? row.engineerUserId1 : undefined,
          engineerName1: row.engineerUserName1,
          engineerId2:
            row.engineerUserId2 !== -1 ? row.engineerUserId2 : undefined,
          engineerName2: row.engineerUserName2,
          engineerId3:
            row.engineerUserId3 !== -1 ? row.engineerUserId3 : undefined,
          engineerName3: row.engineerUserName3,
          engineerComment: row.engineerComment,
          inspectionResultFlg: row.inspectionResultFlg,
          updateDateTime: row.updateDateTime,
        } as InspectionResultDataType;
      });

    setInspecitionResultData(() => {
      const tmp = inspectionResultDataList.filter((it) =>
        (props.yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).includes(it.timing)
      );
      return tmp;
    });
  }

  // グリッドデータ生成
  function getGridDataList(
    inspectionResultList: InspectionResultsGetResponse[]
  ) {
    const griDataList: GridType[] = [];
    if (inspectionResultList.length > 0) {
      // データの縦横変換
      griDataList.push({
        rowType: RowTypes.NextDockName,
        rowTitle: tc("次回入渠ドック"),
      });
      griDataList.push({
        rowType: RowTypes.NextDockPeriod,
        rowTitle: tc("次回入渠ドック予定"),
      });
      griDataList.push({
        rowType: RowTypes.Engineer,
        rowTitle: tc("担当技師"),
      });
      griDataList.push({
        rowType: RowTypes.EngineerComment,
        rowTitle: tc("技師コメント"),
      });
      griDataList.push({
        rowType: RowTypes.ServiceReport,
        rowTitle: tc("サービスレポート"),
      });
      griDataList.push({
        rowType: RowTypes.PictureList,
        rowTitle: tc("写真リスト"),
      });
      if (auth.isEngineer()) {
        griDataList.push({
          rowType: RowTypes.Footer,
          rowTitle: tc("登録"),
        });
      }
    }

    setGridData(griDataList);
  }

  // 定期点検結果写真データリスト生成
  function getInsepectionResultPictureDataList(
    inspectionResultList: InspectionResultsGetResponse[]
  ) {
    const pictureFileList: PictureListType[] = [];
    inspectionResultList
      .sort(
        (a, b) =>
          Number(a.inspectionYearClassValue) -
          Number(b.inspectionYearClassValue)
      )
      .forEach((row: InspectionResultsGetResponse) => {
        // 定期点検結果に紐づく写真リストを取得
        api
          .get(
            `api/v1/vessels/${row.imoNo}/companies/${row.companyCode}/inspection-results/${row.inspectionYearClassValue}/pictures`
          )
          .then((response) => {
            const inspectionResultPicturesData = response.data.map(
              (row: InspectionResultPicturesGetResponse) => {
                return row;
              }
            );

            if (inspectionResultPicturesData.length > 0) {
              //点検後の写真で表示Noの最小のファイル名を取得
              const targetPitureList = inspectionResultPicturesData
                .filter(
                  (it: InspectionResultPicturesGetResponse) =>
                    it.beforeAfterClassValue === "2"
                )
                .sort(
                  (
                    a: InspectionResultPicturesGetResponse,
                    b: InspectionResultPicturesGetResponse
                  ) => a.displayNo - b.displayNo
                );

              if (targetPitureList.length > 0) {
                // 対象の写真名でS3よりファイルを取得する
                getPictureList(targetPitureList[0], pictureFileList);
              }
            }
          })
          .catch((error) => {
            if (getBizError(error)) {
              //対応するエラーメッセージを表示
              showDialog({ error });
            } else {
              showDialog({ id: "E073" });
            }
          });
      });
  }

  // 保存系ボタン
  function handleClickActionButton(name: saveButtonName, timing: number) {
    const messageIdMap = [{ buttonName: "登録", messageId: "I019" }];
    const messageIdSubMap = [
      { buttonName: "登録", messageId: "I035" },
      { buttonName: "一時保存", messageId: "I045" },
    ];
    const messageId = messageIdMap.find(
      (it) => it.buttonName === name
    )?.messageId;

    const messageIdSub =
      messageIdSubMap.find((it) => it.buttonName === name)?.messageId ?? "";

    // 正常処理後の動作
    const saveFunc = () => {
      saveData(timing, name === "一時保存" ? true : false, messageIdSub);
    };

    if (messageId) {
      showDialog({
        id: messageId,
        confirm: true,
        callback: (isOk) => {
          if (isOk) {
            saveFunc();
          }
        },
      });
    } else {
      // メッセージなしで保存
      saveFunc();
    }
    props.setFormChanged(false);
  }

  // データ保存
  function saveData(
    timing: number,
    tempSaveFlg: boolean,
    messageIdSub: string
  ) {
    // サービスレポートを処理
    const uploadPromises = Promise.all([saveCore()]).then(() => {
      // 登録値を編集
      const targetinspecitionResultData = inspecitionResultData.find(
        (it) => it.timing === timing
      );

      if (targetinspecitionResultData) {
        const postRequest = {
          companyCode: targetinspecitionResultData.companyCode,
          imoNo: targetinspecitionResultData.imoNo,
          inspectionYearClassValue:
            targetinspecitionResultData.timing.toString(),
          engineerUserId1: targetinspecitionResultData.engineerId1 ?? -1,
          engineerUserId2: targetinspecitionResultData.engineerId2 ?? -1,
          engineerUserId3: targetinspecitionResultData.engineerId3 ?? -1,
          engineerComment: targetinspecitionResultData.engineerComment,
          inspectionResultUserId: !tempSaveFlg ? user.userId : -1,
          inspectionResultFlg: !tempSaveFlg,
          updateDateTime: targetinspecitionResultData.updateDateTime,
        };
        // 定期点検登録API実行
        api
          .post(
            `/api/v1/vessels/${targetinspecitionResultData.imoNo}/companies/${targetinspecitionResultData.companyCode}/inspection-results/${targetinspecitionResultData.timing}`,
            postRequest
          )
          .then((it) => {
            splashMessage.show(messageIdSub);
            searchData();
          })
          .catch((error) => {
            if (error.response.status === 404) {
              showDialog({ id: "E027" });
            } else {
              if (getBizError(error)) {
                //対応するエラーメッセージを表示
                showDialog({ error });
              } else {
                showDialog({ id: "E026" });
              }
            }
          });
      }
    });
    return uploadPromises;
  }

  // 添付ファイルをS3に登録
  function saveCore() {
    // 既存ファイルをS3から削除
    const deletePromises: any[] = [];
    serviceReportList.forEach((it) => {
      it.serviceReportList
        .filter((file) => file.deleteFlg === true && file.uploadFlg === true)
        .forEach((fileData) => {
          const objectKey = `inspection-result/report/${it.companyCode}-${it.imoNo}-${it.inspectionYearClassValue}/${fileData.fileObject.name}`;
          deletePromises.push(s3.deleteS3File(objectKey, false));
        });
    });
    // 追加指定したファイルをS3にアップロード
    const putPromises: any[] = [];
    serviceReportList.forEach((it) => {
      it.serviceReportList
        .filter((file) => file.deleteFlg === false && file.uploadFlg === false)
        .forEach((fileData) => {
          const objectKey = `inspection-result/report/${it.companyCode}-${it.imoNo}-${it.inspectionYearClassValue}/${fileData.fileObject.name}`;
          putPromises.push(s3.putS3File(objectKey, fileData.fileObject, false));
        });
    });

    return Promise.all([...deletePromises, ...putPromises]);
  }

  // 初回レンダリングのみ実行
  useEffect(() => {
    // 技師コンボボックス
    api.get(`/api/v1/user-suggestions?userClass=engineer`).then((response) => {
      const result = response.data.map((row: UserSuggestionsGetResponse) => {
        return {
          label: row.userName,
          value: row.userId,
        };
      });

      setEngineerOptions(result);
    });
  }, []);

  // サービスレポートリスト生成
  function getServiceReportList(
    inspectionResultData: InspectionResultsGetResponse[]
  ) {
    const serviceReportFiles: ServiceReportListType[] = [];
    inspectionResultData
      .sort(
        (a, b) =>
          Number(a.inspectionYearClassValue) -
          Number(b.inspectionYearClassValue)
      )
      .forEach((row: InspectionResultsGetResponse) => {
        // S3よりサービスレポートを取得
        s3.getS3FileList(
          `inspection-result/report/${row.companyCode}-${row.imoNo}-${row.inspectionYearClassValue}/`,
          false
        ).then((response) => {
          response.data.forEach((objectKey) => {
            // 署名付きURLを取得してからデータ取得
            s3.getS3PresignedUrl("GET", objectKey).then((res2) => {
              const presignedUrl = res2.data;
              loadFile(presignedUrl, objectKey, row, serviceReportFiles);
            });
          });
        });
      });
  }

  // S3からファイルの実データを取得
  function loadFile(
    url: string,
    objectKey: string,
    inspectionResultsGetResponse: InspectionResultsGetResponse,
    serviceReportFiles: ServiceReportListType[]
  ) {
    axios.get<Blob>(url, { responseType: "blob" }).then((blobResponse) => {
      if (blobResponse) {
        const fileName = objectKey.substring(objectKey.lastIndexOf("/") + 1);
        const newFile: FileType[] = [
          {
            fileObject: new File([blobResponse.data], fileName, {
              type: blobResponse.headers["content-type"],
            }),
            uploadFlg: true,
            deleteFlg: false,
          } as FileType,
        ];
        const fileIndex = serviceReportFiles.findIndex(
          (i) =>
            i.inspectionYearClassValue ===
            inspectionResultsGetResponse.inspectionYearClassValue
        );

        if (fileIndex !== -1) {
          serviceReportFiles[fileIndex].serviceReportList = [
            ...serviceReportFiles[fileIndex].serviceReportList,
            ...newFile,
          ];
        } else {
          serviceReportFiles.push({
            companyCode: inspectionResultsGetResponse.companyCode,
            imoNo: inspectionResultsGetResponse.imoNo,
            inspectionYearClassValue:
              inspectionResultsGetResponse.inspectionYearClassValue,
            serviceReportList: newFile,
          } as ServiceReportListType);
        }
        setServiceReportList([...serviceReportFiles]);
      } else {
        setServiceReportList([]);
      }
    });
  }

  // 定期点検写真リスト生成
  function getPictureList(
    pictureData: InspectionResultPicturesGetResponse,
    pictureFileList: PictureListType[]
  ) {
    // S3より定期点検写真を取得
    s3.getS3FileList(
      `inspection-result/after/${pictureData.companyCode}-${pictureData.imoNo}-${pictureData.inspectionYearClassValue}/${pictureData.pictureFileName}`,
      false
    ).then((response) => {
      response.data.forEach((objectKey) => {
        // 署名付きURLを取得してからデータ取得
        s3.getS3PresignedUrl("GET", objectKey).then((res2) => {
          const presignedUrl = res2.data;
          loadPictureFile(
            presignedUrl,
            objectKey,
            pictureData,
            pictureFileList
          );
        });
      });
    });
  }

  // S3からファイルの実データを取得
  function loadPictureFile(
    url: string,
    objectKey: string,
    pictureData: InspectionResultPicturesGetResponse,
    pictureFileList: PictureListType[]
  ) {
    axios.get<Blob>(url, { responseType: "blob" }).then((blobResponse) => {
      if (blobResponse) {
        const fileName = objectKey.substring(objectKey.lastIndexOf("/") + 1);
        const newFile: File = new File([blobResponse.data], fileName, {
          type: blobResponse.headers["content-type"],
        });

        // const pictureFileList = [...pictureDataList];
        pictureFileList.push({
          companyCode: pictureData.companyCode,
          imoNo: pictureData.imoNo,
          inspectionYearClassValue: pictureData.inspectionYearClassValue,
          pictureList: newFile,
        } as PictureListType);

        setPictureDataList([...pictureFileList]);
      }
    });
  }

  function searchData() {
    // 竣工年検索
    if (props.imoNo) {
      api.get(`/api/v1/vessels/${props.imoNo}`).then((response) => {
        setCompletedYear(Number(response.data.year ?? 0));
        setVessel(response.data);
      });
    } else {
      setCompletedYear(0);
      setVessel(undefined);
    }

    // 得意先検索
    if (props.companyCode) {
      api
        .get(`/api/v1/companies/${props.companyCode}?searchMode=0`)
        .then((response) => {
          setCompany(response.data);
        });
    } else {
      setCompany(undefined);
    }

    // メンテナンススケジュールリスト検索
    api
      .get(
        `api/v1/vessels/${props.imoNo ? props.imoNo : "-1"}/companies/${
          props.companyCode ? props.companyCode : "-1"
        }/maintenance-schedules`
      )
      .then((response) => {
        const maintenaceScheduleData = response.data.map(
          (row: MaintenanceSchedulesGetResponse) => {
            return row;
          }
        );
        // メンテンナンススケジュールデータをセット
        setMaintenanceScheduleDataList(maintenaceScheduleData);
      });

    setNoRowsInspectionResult(
      props.imoNo ? t(TextConst.GRID_NO_DATA) : SELECT_VESSEL
    );
    setGridData(null);
    setServiceReportList([]);
    setPictureDataList([]);
    // 定期点検結果リスト検索
    api
      .get(
        `api/v1/vessels/${props.imoNo ? props.imoNo : "-1"}/companies/${
          props.companyCode ? props.companyCode : "-1"
        }/inspection-results`
      )
      .then((response) => {
        const inspectionResultData = response.data.filter(
          // 技師以外の場合は登録済みフラグの条件を付ける
          (filterRow: InspectionResultsGetResponse) =>
            (!auth.isEngineer() && filterRow.inspectionResultFlg) ||
            auth.isEngineer()
        );

        // 定期点検結果リストを取得
        getInsepectionResultDataList(inspectionResultData);

        // グリッドタイトル行データ取得
        getGridDataList(inspectionResultData);

        // S3のサービスレポートデータを取得
        getServiceReportList(inspectionResultData);

        // 定期点検結果写真リストを取得
        getInsepectionResultPictureDataList(inspectionResultData);
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E073" });
        }
      });
  }

  // 親コンポーネントからのプロパティ更新時に実行
  useEffect(() => {
    if (props.imoNo !== imoNo || props.yearCheckValue !== yearCheckValue) {
      setImoNo(props.imoNo);
      setYearCheckValue(props.yearCheckValue);
      searchData();
    }
  }, [props]);

  return (
    <>
      <div style={{ marginTop: "24px", marginBottom: "10px" }}>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {tc("定期点検")}
        </span>
      </div>
      <div
        className={
          "ag-theme-alpine mx-auto position-relative b-header-row-white"
        }
        data-cy="定期点検結果グリッド"
      >
        <AgGridReact
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={gridData}
          overlayNoRowsTemplate={t(noRowsInspectionResult)}
        />
      </div>
      <InspectionResultPicture
        setState={setPirctureData}
        ref={inspectionResultPictureRef}
      />
    </>
  );
}

export default InspectionResult;
