import { ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { ClassValuesGetResponse } from "../../model/api/response/ClassValuesGetResponse";
import { ProductsGetResponse } from "../../model/api/response/ProductsGetResponse";
import {
  agNumberFormatter,
  agProductNameFormatter,
  defaultColDef,
} from "../../util/AgGridUtil";
import { TextConst } from "../../util/Constant";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import { getBizError, isEnglish } from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import SearchArea from "../common/SearchArea";
import TooltipIcon from "../common/TooltipIcon";
import ProductDetail, { ProductDetailHandles } from "./ProductDetail";

let selectable = false;

/** 商品一覧コンポーネント */
function ProductList(props: {
  selectable?: boolean;
  isModal?: boolean;
  onlyLcmCosts?: boolean;
  imoNo?: string;
  onSelect?: (products: ProductsGetResponse[]) => void;
}) {
  // 参照
  const gridRef = useRef<AgGridReact>(null); // グリッド
  const productDetailRef = useRef<ProductDetailHandles>(null); // 商品詳細

  // propsの受け取り
  selectable = props.selectable || false;

  // ログイン情報
  const auth = useAuthUserContext();
  // API使用宣言
  const api = useApi();
  //ダイアログ仕様宣言
  const showDialog = useDialog();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("ProductList");

  const [selectedList, setSelectedList] = useState<ProductsGetResponse[]>([]); // 選択済み商品リスト

  const [gridText, setGridText] = useState(TextConst.GRID_INITIAL); // グリッドのテキスト
  const [showConditionAccordion, setShowConditionAccordion] = useState(true); // 検索条件アコーディオン開閉状態
  const [lastImportDateTime, setLastImportDateTime] = useState();

  // 検索条件（コンボはundefinedが入る）
  type SearchValueType = {
    productCode: string;
    productGroupName: string | undefined;
    spec: string;
    mekerName: string;
  };
  const [searchValues, setSearchValues] = useState<SearchValueType>({
    productCode: "",
    productGroupName: "",
    spec: "",
    mekerName: "",
  });

  // 一覧データ
  const [gridData, setGridData] = useState<ProductsGetResponse[]>([]);

  // 品名コンボ（品名キー入力ごとにAPI通信）
  const productLoadOptions = (inputValue: string) => {
    const productGroupName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/product-group-name-suggestions?productGroupName=${productGroupName}`
      )
      .then((it) => {
        return it.data.map((row: string) => {
          return {
            label: row,
            value: row,
          };
        });
      });
  };

  function setImportDateTime() {
    api.get(`/api/v1/class/csv_upload_datetime/values`).then((response) => {
      const importDateTime = response.data.filter(
        (it: ClassValuesGetResponse) => it.classValue === "PRODUCT"
      )[0].className;
      setLastImportDateTime(importDateTime);
    });
  }

  // モードによって画面幅を変える
  let width = "w-75";
  if (props.isModal) {
    width = "w-100";
  }

  // stateへの設定とpropsの関数呼び出し
  function setSelectedListCore(products: ProductsGetResponse[]) {
    setSelectedList(products);
    if (props.onSelect) {
      props.onSelect(products);
    }
  }

  // 選択中商品リストにあるかどうか
  function isSelectedProduct(
    productCode: string,
    detailNo: number | undefined
  ): boolean {
    const selectedCount = selectedList.filter(
      (it) => productCode === it.productCode && detailNo === it.detailNo
    ).length;
    return selectedCount > 0;
  }

  // 検索エリアテキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    // 入力値をセット
    setSearchValues({ ...searchValues, [name]: value });
  }

  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    // 選択値をセット
    const value = e?.value;
    setSearchValues({ ...searchValues, [name]: value });
  }

  // 検索処理
  function search() {
    const queryParams: string[] = [];
    if (searchValues.productCode) {
      queryParams.push(`productCode=${searchValues.productCode}`);
    }
    if (searchValues.productGroupName) {
      queryParams.push(`productGroupName=${searchValues.productGroupName}`);
    }
    if (searchValues.spec) {
      queryParams.push(`spec=${searchValues.spec}`);
    }
    if (searchValues.mekerName) {
      queryParams.push(`makerName=${searchValues.mekerName}`);
    }
    if (props.onlyLcmCosts) {
      queryParams.push(`onlyLcmCosts=true`);
    }
    if (props.imoNo) {
      queryParams.push(`imoNo=${props.imoNo}`);
    }
    const query = queryParams.join("&");
    api
      .get(`/api/v1/products?${query}`)
      .then((response) => {
        const result = response.data.map((it: ProductsGetResponse) => {
          return {
            productCode: it.productCode,
            productGroupName: it.productGroupName,
            productGroupNameEn: it.productGroupNameEn,
            spec: it.spec,
            makerName: it.makerName,
            unit: it.unit,
            unitPrice: it.unitPrice,
            deliveryDate: it.deliveryDate,
            note: it.note,
            detailNo: it.detailNo,
            largeClassName: it.largeClassName,
            middleClassName: it.middleClassName,
          };
        });
        // 検索結果をセット
        setGridData(result);
        // 検索条件アコーディオンを閉じる
        if (response.data.length > 0) {
          setGridText(TextConst.GRID_NOT_SELECTED);
          setShowConditionAccordion(false);
        } else {
          // グリッドテキスト変更
          setGridText(TextConst.GRID_NO_DATA);
        }
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E073" });
        }
      });
  }

  // 初回レンダリングのみ実行
  useEffect(() => {
    setImportDateTime();
  }, []);

  // 検索ボタンクリック
  function onClickSearchButton() {
    search();
  }

  // 詳細画面保存ボタンクリック
  function handleClickSaveButton(buttonName: string) {
    // 再検索
    search();
  }

  function changeSelectedProductList(
    productCode: string,
    detailNo: number | undefined
  ) {
    if (isSelectedProduct(productCode, detailNo)) {
      const newList = selectedList.filter(
        (it) =>
          it.productCode !== productCode ||
          (it.productCode === productCode && it.detailNo !== detailNo)
      );
      setSelectedListCore(newList);
    } else {
      const selectedProduct = gridData
        .filter(
          (it) => it.productCode === productCode && it.detailNo === detailNo
        )
        .at(0);
      const newList = [...selectedList, selectedProduct!];
      setSelectedListCore(newList);
    }
  }

  //ファイルを選択し、開くを押した後の処理
  function selectFile(e: React.ChangeEvent<HTMLInputElement>) {
    let formData = new FormData();
    formData.append("productCsv", e.currentTarget.files![0]);
    api
      .post(`/api/v1/products-csv`, formData)
      .then((it) => {
        splashMessage.show("I044");
        setImportDateTime();
        search();
      })
      .catch((error) => {
        //csvエラーファイルが返ってきているかどうか
        if (error.response) {
          if (error.response.data !== "") {
            //文字化け回避
            const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
            const csv = new Blob([bom, error.response.data], {
              type: "text/csv",
            });
            //aタグ作成
            const a = document.createElement("a");
            // ダウンロードされるファイル名
            a.download = "エラーファイル_商品マスタ取込.csv";
            a.href = URL.createObjectURL(csv);
            // ダウンロード開始
            a.click();
          } else {
            if (getBizError(error)) {
              //対応するエラーメッセージを表示
              showDialog({ error });
            } else {
              showDialog({ id: "E010" });
            }
          }
        } else {
          showDialog({ id: "E010" });
        }
      });
  }

  // グリッドの列定義
  const columnDefs = [
    {
      headerName: "",
      field: "select",
      width: isEnglish() ? 105 : 100,
      cellRenderer: (params: any) => {
        return (
          <input
            type="button"
            value={
              isSelectedProduct(params.data.productCode, params.data.detailNo)
                ? tc("選択中")
                : tc("選択")
            }
            className="btn btn-sm text-white"
            style={
              isSelectedProduct(params.data.productCode, params.data.detailNo)
                ? { backgroundColor: "#00b0f0" }
                : { backgroundColor: "#e76300" }
            }
            onClick={() => {
              changeSelectedProductList(
                params.data.productCode,
                params.data.detailNo
              );
            }}
          />
        );
      },
      hide: !selectable,
    },
    {
      headerName: tc("品目コード"),
      field: "productCode",
      width: 110,
      cellRenderer: (params: any) => {
        return (
          <a
            className="text-link"
            href="#"
            onClick={() =>
              productDetailRef.current?.show(params.value, params.data.detailNo)
            }
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: tc("品名"),
      field: "productGroupName",
      width: 160,
      valueFormatter: agProductNameFormatter,
    },
    {
      headerName: tc("型式"),
      field: "spec",
      width: 330,
    },
    {
      headerName: tc("メーカー名"),
      field: "makerName",
      width: 150,
      sortable: true,
    },
    {
      headerName: tc("単位"),
      field: "unit",
      width: 80,
    },
    {
      headerName: tc("単価"),
      field: "unitPrice",
      width: isEnglish() ? 130 : 120,
      sortable: true,
      cellClass: "b-grid-cell-multi-line justify-content-end",
      cellClassRules: {
        "text-start": (params: any) =>
          //要問合せの場合は文字を左揃えにする
          params.value === null ||
          params.value === undefined ||
          isNaN(params.value),
      },
      valueFormatter: (e: any) => agNumberFormatter(e, tc("要問合せ")),
    },
    {
      headerName: tc("標準納期"),
      field: "deliveryDate",
      width: isEnglish() ? 115 : 100,
      cellClass: "b-grid-cell-multi-line",
      sortable: true,
    },
    {
      headerName: tc("備考"),
      field: "note",
      width: 200,
      tooltipField: "note",
    },
  ];

  // グリッドの列定義(部品交換リスト用)
  const vesselColumnDefs = [
    {
      headerName: "",
      field: "select",
      width: isEnglish() ? 105 : 100,
      cellRenderer: (params: any) => {
        return (
          <input
            type="button"
            value={
              isSelectedProduct(params.data.productCode, params.data.detailNo)
                ? tc("選択中")
                : tc("選択")
            }
            className="btn btn-sm text-white"
            style={
              isSelectedProduct(params.data.productCode, params.data.detailNo)
                ? { backgroundColor: "#00b0f0" }
                : { backgroundColor: "#e76300" }
            }
            onClick={() => {
              changeSelectedProductList(
                params.data.productCode,
                params.data.detailNo
              );
            }}
          />
        );
      },
      hide: !selectable,
    },
    {
      headerName: tc("大分類"),
      field: "largeClassName",
      width: 120,
    },
    {
      headerName: tc("中分類"),
      field: "middleClassName",
      width: 120,
    },
    {
      headerName: tc("品目コード"),
      field: "productCode",
      width: 110,
      cellRenderer: (params: any) => {
        return (
          <a
            className="text-link"
            href="#"
            onClick={() =>
              productDetailRef.current?.show(params.value, params.data.detailNo)
            }
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: tc("品名"),
      field: "productGroupName",
      width: 120,
      valueFormatter: agProductNameFormatter,
    },
    {
      headerName: tc("型式"),
      field: "spec",
      width: 200,
    },
    {
      headerName: tc("メーカー名"),
      field: "makerName",
      width: 150,
      sortable: true,
    },
    {
      headerName: tc("単位"),
      field: "unit",
      width: 80,
    },
    {
      headerName: tc("単価"),
      field: "unitPrice",
      cellClass: "b-grid-cell-multi-line justify-content-end",
      width: 120,
      sortable: true,
      cellClassRules: {
        "text-start": (params: any) =>
          //要問合せの場合は文字を左揃えにする
          params.value === null ||
          params.value === undefined ||
          isNaN(params.value),
      },
      valueFormatter: (e: any) => agNumberFormatter(e, tc("要問合せ")),
    },
    {
      headerName: tc("標準納期"),
      field: "deliveryDate",
      width: isEnglish() ? 115 : 100,
      cellClass: "b-grid-cell-multi-line",
      sortable: true,
    },
    {
      headerName: tc("備考"),
      field: "note",
      width: 200,
      tooltipField: "note",
    },
  ];

  // グリッドの列定義（選択済み商品一覧）
  const columnDefs2 = [
    {
      headerName: "",
      field: "select",
      width: isEnglish() ? 105 : 100,
      cellRenderer: (params: any) => {
        return (
          <input
            type="button"
            value={tc("選択中")}
            className="btn btn-sm text-white"
            style={{ backgroundColor: "#00b0f0" }}
            onClick={() => {
              const newList = selectedList.filter(
                (it) => it.productCode !== params.data.productCode
              );
              setSelectedListCore(newList);
            }}
          />
        );
      },
    },
    {
      headerName: tc("品目コード"),
      field: "productCode",
      width: 110,
      sortable: true,
      cellRenderer: (params: any) => {
        return (
          <a
            href="#"
            onClick={() =>
              productDetailRef.current?.show(params.value, params.data.detailNo)
            }
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: tc("品名"),
      field: "productGroupName",
      width: 150,
      sortable: true,
      valueFormatter: agProductNameFormatter,
    },
    {
      headerName: tc("型式"),
      field: "spec",
      width: 500,
      sortable: true,
    },
  ];

  // 選択済み商品エリアレンダリング
  function SelectedProductsView() {
    return (
      <>
        <div style={{ margin: "20px 30px 0px 30px", fontWeight: "bold" }}>
          {tc("選択中商品")}
          <TooltipIcon messageId="T003"></TooltipIcon>
        </div>
        <div
          className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
          style={{
            minHeight: "450px",
            position: "relative",
            margin: "10px 30px 0px 30px",
          }}
          data-cy="選択中商品グリッド"
        >
          <AgGridReact
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs2}
            rowData={selectedList}
            overlayNoRowsTemplate={t(gridText)}
          />
        </div>
      </>
    );
  }

  // レンダリング
  return (
    <>
      <div className="b-container-list">
        {props.isModal ? (
          <></>
        ) : (
          <div className="title-area-style">
            <span>{tc("商品一覧")}</span>
          </div>
        )}

        <SearchArea
          show={showConditionAccordion}
          toolTip={
            <TooltipIcon messageId="T001" args={[100, tc("品目コード")]} />
          }
          onChangeShow={(value) => {
            setShowConditionAccordion(value);
          }}
        >
          <div className="row">
            <div className="col-3 d-flex">
              <span className="col-form-label px-2 text-nowrap">
                {tc("品目コード")}
              </span>
              <input
                type="text"
                name="productCode"
                className="form-control border-0"
                maxLength={10}
                placeholder={TextConst.INPUT_PLACEHOLDER}
                value={searchValues.productCode}
                onChange={handleChangeInput}
                data-cy="品目コードテキスト"
              />
            </div>
            <div className="col-3 d-flex">
              <span className="col-form-label px-2 text-nowrap">
                {tc("品名")}
              </span>
              <span className="flex-grow-1" data-cy="品名コンボ">
                <AsyncSelect
                  styles={defaultSelectStyle}
                  placeholder={TextConst.COMBO_PLACEHOLDER}
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={productLoadOptions}
                  onChange={(e) => {
                    handleChangeSelect("productGroupName", e);
                  }}
                />
              </span>
            </div>
            <div className="col-3 d-flex">
              <span className="col-form-label px-2 text-nowrap">
                {tc("型式")}
              </span>
              <input
                type="text"
                name="spec"
                className="form-control border-0"
                maxLength={40}
                placeholder={TextConst.INPUT_PLACEHOLDER}
                value={searchValues.spec}
                onChange={handleChangeInput}
                data-cy="型式テキスト"
              />
            </div>
            <div className="col-3 d-flex">
              <span className="col-form-label px-2 text-nowrap">
                {tc("メーカー名")}
              </span>
              <input
                type="text"
                name="mekerName"
                className="form-control border-0"
                maxLength={20}
                placeholder={TextConst.INPUT_PLACEHOLDER}
                value={searchValues.mekerName}
                onChange={handleChangeInput}
                data-cy="メーカー名テキスト"
              />
            </div>
          </div>
          <div className="text-center">
            <input
              type="button"
              className="btn b-btn-primary"
              value={tc("検索")}
              onClick={(e) => onClickSearchButton()}
              data-cy="検索ボタン"
            />
          </div>
        </SearchArea>
        {props.isModal && (
          <>
            <div style={{ margin: "0px 30px 0px 30px", fontWeight: "bold" }}>
              {tc("検索結果一覧")}
              <TooltipIcon messageId="T002"></TooltipIcon>
            </div>
            {!auth.user().bemacFlg && (
              <div
                className="text-end"
                style={{ marginRight: "30px", color: "red", fontSize: "0.9em" }}
              >
                {tc("※標準納期は実際の納期と異なる場合がございます。")}
              </div>
            )}
          </>
        )}
        <div
          className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
          style={{ position: "relative", margin: "0px 30px 0px 30px" }}
          data-cy="商品一覧グリッド"
        >
          <AgGridReact
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            columnDefs={props.imoNo ? vesselColumnDefs : columnDefs}
            rowData={gridData}
            overlayNoRowsTemplate={t(gridText)}
            pagination
            paginationPageSize={20}
            ref={gridRef}
          />
          <PageSizeSelector grid={gridRef.current} />
        </div>
        {!props.isModal && (
          <div
            className="text-end"
            style={{ color: "gray", marginRight: "250px" }}
          >
            {tc("前回CSV取込成功日時")} : {lastImportDateTime}
          </div>
        )}
        <input
          type="file"
          id="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={selectFile}
          onClick={(e: any) => {
            e.target.value = "";
          }}
          data-cy="CSVファイル選択"
        />
        {selectable && <SelectedProductsView />}
        {!selectable && auth.isBemacAdmin() && (
          <div className="text-center my-2">
            <button
              type="button"
              className="btn b-btn-primary mt-5 mb-0 mx-auto"
              onClick={() => document.getElementById("file")?.click()}
              data-cy="CSV取込ボタン"
            >
              {tc("CSV取込")}
            </button>
          </div>
        )}
        <ProductDetail
          isMaintenanceMode={!selectable}
          onClick={changeSelectedProductList}
          onClickSaveButton={handleClickSaveButton}
          isSelectedProduct={isSelectedProduct}
          ref={productDetailRef}
        />
      </div>
    </>
  );
}

export default ProductList;
