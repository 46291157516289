import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { useSplashMessage } from "src/hook/useSplashMessage";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useS3 } from "../../hook/useS3";
import { UsersDeletePostRequest } from "../../model/api/request/UsersDeletePostRequest";
import { UsersDeletePostRequestSub } from "../../model/api/request/UsersDeletePostRequestSub";
import { ClassValuesGetResponse } from "../../model/api/response/ClassValuesGetResponse";
import { CompanySuggestionsGetResponse } from "../../model/api/response/CompanySuggestionsGetResponse";
import { UsersGetResponse } from "../../model/api/response/UsersGetResponse";
import { defaultColDef } from "../../util/AgGridUtil";
import { TextConst } from "../../util/Constant";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import { getBizError } from "../../util/ecUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import SearchArea from "../common/SearchArea";
import TooltipIcon from "../common/TooltipIcon";
import UserDetail, {
  UserDetailHandles,
  statusMasterBemac,
  statusMasterCustomer,
  statusUpdate,
} from "./UserDetail";

//主処理
function UserList() {
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("UserList");

  //定数定義
  const USER_LIST = "ユーザ一覧";
  const FILE_NAME_ERROR_USER_LIST_CSV_OUTPUT =
    "エラーファイル_ユーザ一覧CSV出力.csv";

  //ユーザ画像URL
  const USER_PICTURE_URL = "user-image/";

  // 検索条件アコーディオン開閉状態
  const [showConditionAccordion, setShowConditionAccordion] = useState(true);
  /// 明細部定義
  const gridRef = useRef<AgGridReact>(null);
  // ユーザ詳細定義
  const UserDetailRef = useRef<UserDetailHandles>(null);
  // S3使用宣言
  const s3 = useS3();
  // API使用宣言
  const api = useApi();

  // 検索エリアテキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    // 入力値をセット
    setSearchValues({ ...searchValues, [name]: value });
  }

  //ダイアログ仕様宣言
  const showDialog = useDialog();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  // 検索条件（コンボはundefinedが入る）
  type SearchValueType = {
    companyCode: string | undefined;
    userId: number | undefined;
    userName: string | undefined;
    mailAddress: string | undefined;
    bemacFlg: boolean | undefined;
    bemacUserClassValue: string | undefined;
  };
  let [searchValues, setSearchValues] = useState<SearchValueType>({
    companyCode: "",
    userId: undefined,
    userName: "",
    mailAddress: "",
    bemacFlg: true,
    bemacUserClassValue: "",
  });

  //BEMACユーザ検索フラグ
  let [searchBemacFlg, setSearchBemacFlg] = useState(true);
  //BEMACユーザ分類トグル
  const [userTypeTgl, setUserTypeTgl] = useState(true);
  //BEMACユーザ分類テキスト
  const [userTypeTxt, setUserTypeTxt] = useState("BEMACユーザ");

  // BEMACユーザ分類コンボ
  const [bemacClassOptions, setBemacClassOptions] = useState<
    SelectOption<string>[]
  >([]);

  // 一覧データ
  const [data, setData] = useState<UsersGetResponse[]>([]);

  // 明細0件時のテキスト
  const [noRowsText, setNoRowsText] = useState(TextConst.GRID_INITIAL);

  // ログインユーザ情報
  const auth = useAuthUserContext();

  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    // 選択値をセット
    const value = e?.value;
    setSearchValues({ ...searchValues, [name]: value });
  }

  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(`/api/v1/company-suggestions?companyName=${companyName}`)
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };

  // 初回レンダリングのみ実行
  useEffect(() => {
    // BEMACユーザ分類コンボ
    //分類値マスタ取得APIを呼び出し
    api.get(`/api/v1/class/bemac_user_role/values`).then((response) => {
      const bemacRole = response.data.map((it: ClassValuesGetResponse) => {
        return {
          label: it.className,
          value: it.classValue,
        };
      });
      setBemacClassOptions(bemacRole);
    });
    //検索エリアのメッセージを初期化
    setNoRowsText(TextConst.GRID_INITIAL);
    //顧客ユーザの場合の画面表示制御
    if (!auth.user().bemacFlg) {
      setSearchBemacFlg(false);
      setUserTypeTgl(false);
      searchValues.companyCode = auth.user().companyCode;
    }
  }, []);

  // 詳細画面保存ボタンクリック
  function handleClickSaveButton(buttonName: string) {
    // 再検索
    onClickSearchButton();
  }

  // イベント
  // 検索ボタンクリック
  function onClickSearchButton() {
    const queryParams: string[] = [];

    //検索条件を設定
    //ユーザID
    if (searchValues.userId) {
      queryParams.push(`userId=${searchValues.userId}`);
    }
    //ユーザ名
    if (searchValues.userName) {
      queryParams.push(`userName=${searchValues.userName}`);
    }
    //Mail
    if (searchValues.mailAddress) {
      queryParams.push(`mailAddress=${searchValues.mailAddress}`);
    }
    //ユーザ分類
    queryParams.push(`bemacFlg=${userTypeTgl}`);

    //BEMACユーザの検索
    if (userTypeTgl) {
      //BEMACユーザ分類
      if (searchValues.bemacUserClassValue) {
        queryParams.push(
          `bemacUserClassValue=${searchValues.bemacUserClassValue}`
        );
      }
    } else {
      //顧客ユーザの検索
      //得意先コード
      if (searchValues.companyCode) {
        queryParams.push(`companyCode=${searchValues.companyCode}`);
      }
    }
    const query = queryParams.join("&");
    //得意先リスト取得APIを呼び出し
    api
      .get(`/api/v1/users?${query}`)
      .then((response) => {
        // グリッドテキスト変更
        setNoRowsText(TextConst.GRID_NO_DATA);
        // 検索結果をセット
        setData(response.data);
        // 検索条件アコーディオンを開閉を設定
        if (response.data.length > 0) {
          setShowConditionAccordion(false);
        } else {
          setShowConditionAccordion(true);
        }
      })
      .catch((error) => {
        // エラー時の処理
        //検索結果0に
        const usersGetResponse: UsersGetResponse[] = [];
        setData(usersGetResponse);
        setNoRowsText(TextConst.GRID_NO_DATA);
        setShowConditionAccordion(true);
      });
  }

  // 削除ボタンクリック
  function handleClickDeleteButton() {
    // 選択された行を抽出
    const usersDeletePostRequestSub: UsersDeletePostRequestSub[] = [];
    gridRef.current?.api.getSelectedRows().forEach((row: UsersGetResponse) => {
      usersDeletePostRequestSub.push({
        userId: row.userId,
        muserUpdateDateTime: row.muserUpdateDateTime,
        companyCode: row.companyCode,
      });
    });

    const count = usersDeletePostRequestSub
      ? usersDeletePostRequestSub.length
      : 0;
    //  1件も選択されてなければエラー
    if (count === 0) {
      showDialog({ id: "E023" });
      return;
    }
    const request: UsersDeletePostRequest = {
      usersDeletePostRequestSub: usersDeletePostRequestSub,
    };
    // 確認ダイアログを表示
    showDialog({
      id: "I003",
      args: [count],
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合
          // API実行
          api
            .post(`/api/v1/users:delete`, request)
            .then((response) => {
              // 画像の削除
              saveDelete(request);
              splashMessage.show("I036");
              // 再検索
              onClickSearchButton();
            })
            .catch((error) => {
              if (error.response.status === 404) {
                showDialog({ id: "E075" });
              } else {
                // エラー時の処理
                showDialog({ error });
              }
            });
        }
      },
    });
  }
  // 添付ファイルをS3に登録
  function saveDelete(request: UsersDeletePostRequest) {
    // S3から削除
    request.usersDeletePostRequestSub.forEach((it) => {
      const url = USER_PICTURE_URL + it.userId + "/";
      // 画像のURL取得
      s3.getS3FileList(url).then((res1: { data: any }) => {
        const s3FileList = res1.data;
        s3.deleteS3File(s3FileList[0], false);
      });
    });
  }

  //ユーザCSV出力
  function outputFileUser() {
    //ユーザCSV出力APIを呼び出し
    api
      .get(`/api/v1/users-csv`)
      .then((it) => {
        //文字化け回避
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const csv = new Blob([bom, it.data], {
          type: "text/csv",
        });
        //aタグ作成
        const a = document.createElement("a");
        const date = new Date();
        //日付フォーマットをyyyyMMddに
        const outputDate =
          String(date.getFullYear()) +
          String(date.getMonth() + 1).padStart(2, "0") +
          String(date.getDate()).padStart(2, "0");

        // ダウンロードされるファイル名
        a.download = USER_LIST + outputDate + ".csv";
        a.href = URL.createObjectURL(csv);
        // ダウンロード開始
        a.click();
        splashMessage.show("I055");
      })
      .catch((error) => {
        //csvエラーファイルが返ってきているかどうか
        if (error.response.data !== "") {
          //文字化け回避
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const csv = new Blob([bom, error.response.data], {
            type: "text/csv",
          });
          //aタグ作成
          const a = document.createElement("a");
          // ダウンロードされるファイル名
          a.download = FILE_NAME_ERROR_USER_LIST_CSV_OUTPUT;
          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          }
        }
      });
  }

  //ユーザ分類トグル押下イベント
  function userTypeTglChange() {
    if (userTypeTgl === false) {
      setSearchBemacFlg(true);
      setUserTypeTgl(true);
      setUserTypeTxt("BEMACユーザ");
    } else {
      setSearchBemacFlg(false);
      setUserTypeTgl(false);
      setUserTypeTxt("顧客ユーザ");
    }
  }

  // グリッドの列定義（ユーザ一覧）
  const columnDefs = [
    {
      headerName: "",
      colId: "select",
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: tc("ユーザID"),
      field: "userId",
      width: 100,
      sortable: true,
      cellRenderer: (params: any) => {
        return (
          <a
            href="#"
            className="text-link"
            onClick={() =>
              UserDetailRef.current?.show(
                params.value,
                statusUpdate,
                params.data.bemacFlg
              )
            }
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: tc("ユーザ名"),
      field: "userName",
      width: 230,
      sortable: true,
    },
    {
      headerName: tc("得意先名"),
      field: "companyName",
      width: 330,
      sortable: true,
      hide: searchBemacFlg || !auth.user().bemacFlg,
    },
    {
      headerName: tc("BEMACユーザ分類"),
      field: "bemacUserClassValueName",
      width: 180,
      sortable: true,
      hide: !searchBemacFlg,
    },
    {
      headerName: tc("権限"),
      field: "adminValue",
      width: 180,
      sortable: true,
    },

    {
      headerName: tc("電話番号"),
      field: "userTel",
      sortable: true,
    },
    {
      headerName: "Mail",
      field: "mailAddress",
      width: 300,
      sortable: true,
    },
    {
      headerName: "得意先コード",
      field: "companyCode",
      hide: true,
    },
    {
      headerName: "BEMACユーザ分類",
      field: "bemacFlg",
      hide: true,
    },
    {
      headerName: "更新日時",
      field: "muserUpdateDateTime",
      hide: true,
    },
  ];

  // レンダリング
  return (
    <div>
      <div className="b-container-list">
        <div className="title-area-style">
          <span>{tc("ユーザ一覧")}</span>
        </div>
        {/* 検索条件定義 */}
        <SearchArea
          show={showConditionAccordion}
          toolTip={
            <TooltipIcon
              messageId="T001"
              args={[100, tc("ユーザID")]}
              data-cy="検索上限ツールチップ"
            />
          }
          onChangeShow={(value) => {
            setShowConditionAccordion(value);
          }}
        >
          {/* 1行目 */}

          <div className="row">
            {!searchBemacFlg && auth.user().bemacFlg && (
              <div className="col d-flex">
                <span className="col-form-label px-2 text-nowrap">得意先</span>
                <div className="flex-grow-1" data-cy="得意先名コンボ">
                  {/* 得意先名 */}
                  <AsyncSelect
                    styles={defaultSelectStyle}
                    placeholder={TextConst.COMBO_PLACEHOLDER}
                    isClearable
                    cacheOptions
                    defaultOptions
                    loadOptions={companyOptions}
                    onChange={(e) => {
                      handleChangeSelect("companyCode", e);
                    }}
                  />
                </div>
              </div>
            )}
            {/* ユーザID */}
            <div className="col d-flex">
              <span className="col-form-label px-2 text-nowrap">
                {tc("ユーザＩＤ")}
              </span>
              <input
                name="userId"
                type="text"
                maxLength={9}
                value={searchValues.userId}
                onChange={handleChangeInput}
                className="form-control"
                placeholder={TextConst.INPUT_PLACEHOLDER}
                data-cy={
                  !searchBemacFlg && auth.user().bemacFlg
                    ? "BEMAC用ユーザIDテキスト"
                    : "ユーザIDテキスト"
                }
              />
            </div>
            {/* ユーザ名 */}
            <div className="col d-flex">
              <span className="col-form-label px-2 text-nowrap">
                {tc("ユーザ名")}
              </span>
              <input
                name="userName"
                type="text"
                value={searchValues.userName}
                onChange={handleChangeInput}
                maxLength={100}
                className="form-control"
                placeholder={TextConst.INPUT_PLACEHOLDER}
                data-cy={
                  !searchBemacFlg && auth.user().bemacFlg
                    ? "BEMAC用ユーザ名テキスト"
                    : "ユーザ名テキスト"
                }
              />
            </div>
            {/* Mail */}
            <div className="col d-flex">
              <span className="col-form-label px-2 text-nowrap">Mail</span>
              <input
                name="mailAddress"
                type="text"
                maxLength={256}
                value={searchValues.mailAddress}
                onChange={handleChangeInput}
                className="form-control"
                placeholder={TextConst.INPUT_PLACEHOLDER}
                data-cy={
                  !searchBemacFlg && auth.user().bemacFlg
                    ? "BEMAC用Mailテキスト"
                    : "Mailテキスト"
                }
              />
            </div>
          </div>

          {/* 2行目 */}
          <div className="row mt-3">
            {auth.user().bemacFlg && (
              <div className="col-4 d-flex">
                <span className="col-form-label px-2 text-nowrap">
                  {tc("ユーザ種別")}
                </span>
                <div className="input-group">
                  {/* トグル */}
                  {auth.user().bemacFlg && (
                    <div
                      className="form-check form-switch"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "1.5",
                      }}
                    >
                      <input
                        className="form-check-input"
                        style={{ marginLeft: "-2rem", fontSize: 20, width: 60 }}
                        type="checkbox"
                        checked={userTypeTgl}
                        onChange={userTypeTglChange}
                        data-cy="ユーザ種別トグル"
                      />
                      <span
                        className="mx-3 mt-1 text-nowrap"
                        data-cy="ユーザ種別名"
                      >
                        {tc(userTypeTxt)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {auth.user().bemacFlg && searchBemacFlg && (
              <div className="col-4 d-flex">
                <span className="col-form-label px-2 text-nowrap">
                  {tc("BEMACユーザ分類")}
                </span>
                <div className="flex-grow-1" data-cy="BEMACユーザ分類コンボ">
                  {/* BEMACユーザ分類 */}
                  <Select
                    instanceId="bemacUserClassValue"
                    styles={defaultSelectStyle}
                    placeholder={TextConst.COMBO_PLACEHOLDER}
                    isClearable
                    options={bemacClassOptions}
                    onChange={(e) => {
                      handleChangeSelect("bemacUserClassValue", e);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          {/* 検索ボタン */}
          <div className="text-center">
            <input
              type="button"
              className="btn b-btn-primary"
              value={tc("検索")}
              onClick={(e) => onClickSearchButton()}
              data-cy="検索ボタン"
            />
          </div>
        </SearchArea>
        <div
          className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
          style={{ position: "relative", margin: "10px 30px 0px 30px" }}
          data-cy="ユーザ一覧グリッド"
        >
          {/* グリッド定義 */}
          <AgGridReact
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowSelection="multiple"
            suppressRowClickSelection
            pagination={true}
            paginationPageSize={20}
            rowData={data}
            ref={gridRef}
            overlayNoRowsTemplate={t(noRowsText)}
          />
          <PageSizeSelector grid={gridRef.current} />
        </div>
        {/* ボタン定義 */}
        <div className="row   mt-1  col-12">
          <div className="text-center my-2">
            {auth.user().bemacFlg && (
              <input
                type="button"
                className="btn b-btn-primary"
                value={tc("BEMAC新規登録")}
                onClick={() =>
                  UserDetailRef.current?.show(
                    undefined,
                    statusMasterBemac,
                    true
                  )
                }
                style={{ minWidth: 150 }}
                data-cy="BEMAC新規登録ボタン"
              />
            )}
            {auth.user().bemacFlg && (
              <input
                type="button"
                className="btn b-btn-primary ms-3"
                value={tc("顧客新規登録")}
                onClick={() =>
                  UserDetailRef.current?.show(
                    undefined,
                    statusMasterCustomer,
                    false
                  )
                }
                style={{ minWidth: 150 }}
                data-cy="顧客新規登録ボタン"
              />
            )}
            {!auth.user().bemacFlg && (
              <input
                type="button"
                className="btn b-btn-primary ms-3"
                value={tc("新規登録")}
                onClick={() =>
                  UserDetailRef.current?.show(
                    undefined,
                    statusMasterCustomer,
                    false
                  )
                }
                style={{ minWidth: 150 }}
                data-cy="新規登録ボタン"
              />
            )}

            <input
              type="button"
              className="btn b-btn-delete ms-3"
              style={{ minWidth: "120px" }}
              value={tc("削除")}
              onClick={() => handleClickDeleteButton()}
              data-cy="削除ボタン"
            />
            {auth.user().bemacFlg && (
              <input
                type="button"
                className="btn b-btn-primary ms-3"
                value={tc("CSV出力")}
                style={{ minWidth: 150 }}
                onClick={() => outputFileUser()}
                data-cy="CSV出力ボタン"
              />
            )}
            {auth.user().bemacFlg && (
              <TooltipIcon
                messageId="T006"
                position="up-left"
                data-cy="CSV出力ツールチップ"
              />
            )}
          </div>
        </div>
        <UserDetail
          ref={UserDetailRef}
          onClickSaveButton={handleClickSaveButton}
        />
      </div>
    </div>
  );
}

export default UserList;
