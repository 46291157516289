import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { CompanyGetResponse } from "../../model/api/response/CompanyGetResponse";
import { MaintenanceSchedulesGetResponse } from "../../model/api/response/MaintenanceSchedulesGetResponse";
import { VesselGetResponse } from "../../model/api/response/VesselGetResponse";
import { VesselProductsGetResponse } from "../../model/api/response/VesselProductsGetResponse";
import { OrderStatus } from "../../util/Constant";
import { getBizError, isEnglish } from "../../util/ecUtil";
import {
  INSPECTION_DETAIL_BOTH,
  INSPECTION_DETAIL_INSPECTION,
  INSPECTION_DETAIL_PARTS,
  TIMINGS,
  TIMING_MAIN,
  TIMING_SUBS,
} from "../../util/lcm/LcmConst";
import TooltipIcon from "../common/TooltipIcon";
import PartsReplaceList, { PartsReplaceListHandles } from "./PartsReplaceList";

type Props = {
  imoNo: string | undefined;
  companyCode: string | undefined;
  yearCheckValue: number;
};

type DataType = {
  timing: number; // 実施年
  isDone: boolean; // 実施状況
  hasPlan: boolean; // 定期点検トグル
  inspectionCost: number; // 定期点検
  withPartsCost: boolean; // 部品代トグル
  partsCost: number; // 部品代
  otherCost: number; // 経費
  orderNo: undefined | number; // 注文番号
  compQuotationFlg: boolean; // 完工見積フラグ
  orderCreateUserId: undefined | number; // 注文作成ユーザID
};

type GridType = {
  rowType: RowTypes;
  rowTitle: string;
};

enum RowTypes {
  IsDone,
  HasPlan,
  InspectionCost,
  PartsCost,
  OtherCost,
  Summary,
}

/** コスト推移コンポーネント */
function RepairCostSimulation(props: Props) {
  // API使用宣言
  const api = useApi();

  //ダイアログ仕様宣言
  const showDialog = useDialog();
  // 翻訳
  const { t: tc } = useTranslation("RepairCostSimulation");

  // 折れ線グラフ表示有無
  const [isLineChart, setIsLineChart] = useState(false);

  // 想定部品代
  const INSPECTION_COST = 800000;

  // 想定経費
  const OTHER_COST = 150000;

  // 表示グラフチェックボックスリスト
  const showGraphList = [
    { value: 0, text: tc("棒グラフ") },
    { value: 1, text: tc("折れ線グラフ") },
  ];

  // IMO
  const [imoNo, setImoNo] = useState<string>();

  // 表示年度切替
  const [yearCheckValue, setYearCheckValue] = useState<number>(
    props.yearCheckValue
  );

  // 得意先コード
  const [companyCode, setCompanyCode] = useState<string>();

  // 竣工年
  const [completedYear, setCompletedYear] = useState<number>(0);

  // 船舶情報
  const [vessel, setVessel] = useState<VesselGetResponse>();

  // 得意先情報
  const [company, setCompany] = useState<CompanyGetResponse>();

  // 交換推奨部品情報
  const [vesselProduct, setVesselProduct] = useState<
    VesselProductsGetResponse[]
  >([]);

  // 表示データ
  const [data, setData] = useState(() => {
    const tmp = (yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).map((timing) => {
      return {
        timing: timing,
        isDone: false,
        hasPlan: false,
        inspectionCost: INSPECTION_COST,
        withPartsCost: false,
        partsCost: 0,
        otherCost: 0,
        orderNo: undefined as number | undefined,
        compQuotationFlg: false,
        orderCreateUserId: undefined as number | undefined,
      };
    });
    return tmp;
  });

  // 参照
  const partsReplaceListRef = useRef<PartsReplaceListHandles>(null); // 部品交換内容一覧

  // 表示グラフ種別
  const [selectedShowGraph, setSelectedStatus] = useState(0);

  // グリッドデータ(項目列)
  const gridData: GridType[] = [];
  gridData.push({
    rowType: RowTypes.IsDone,
    rowTitle: tc("実施状況"),
  });
  gridData.push({
    rowType: RowTypes.InspectionCost,
    rowTitle: tc("定期点検"),
  });
  gridData.push({
    rowType: RowTypes.PartsCost,
    rowTitle: tc("部品代"),
  });
  gridData.push({
    rowType: RowTypes.OtherCost,
    rowTitle: tc("経費"),
  });
  gridData.push({
    rowType: RowTypes.Summary,
    rowTitle: tc("合計"),
  });

  // 表示グラフのチェックボックスのスタイル
  const selectGraphStyle: React.CSSProperties = {
    backgroundColor: "#3778F4",
    color: "white",
  };
  const notSelectGraphStyle: React.CSSProperties = {
    backgroundColor: "white",
    color: "black",
  };

  // props更新時実行
  useEffect(() => {
    // 交換推奨部品リスト取得
    if (
      props.imoNo !== imoNo ||
      props.companyCode !== companyCode ||
      props.yearCheckValue !== yearCheckValue
    ) {
      setImoNo(props.imoNo);
      setCompanyCode(props.companyCode);
      searchData();
      setYearCheckValue(props.yearCheckValue);
      api
        .get(
          `/api/v1/vessels/${props.imoNo}/products?companyCode=${props.companyCode}`
        )
        .then((response) => {
          const result = response.data.map((it: VesselProductsGetResponse) => {
            return it;
          });

          search(result);
        })
        .catch((error) => {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E073" });
          }
        });
    }
  }, [props]);

  function searchData() {
    // 竣工年検索
    if (props.imoNo) {
      api.get(`/api/v1/vessels/${props.imoNo}`).then((response) => {
        setCompletedYear(Number(response.data.year ?? 0));
        setVessel(response.data);
      });
    } else {
      setCompletedYear(0);
      setVessel(undefined);
    }

    // 得意先検索
    if (props.companyCode) {
      api
        .get(`/api/v1/companies/${props.companyCode}?searchMode=0`)
        .then((response) => {
          setCompany(response.data);
        });
    } else {
      setCompany(undefined);
    }
  }

  // 検索処理
  function search(vesselProductData: VesselProductsGetResponse[]) {
    // メンテナンススケジュールリスト取得
    api
      .get(
        `api/v1/vessels/${props.imoNo}/companies/${props.companyCode}/maintenance-schedules`
      )
      .then((response) => {
        const maintenaceScheduleData: DataType[] = response.data
          .filter(
            (it: MaintenanceSchedulesGetResponse) =>
              it.orderStatusClassValue === OrderStatus.COMPLETED
          )
          .map((row: MaintenanceSchedulesGetResponse) => {
            return {
              timing: Number(row.inspectionYearClassValue),
              isDone: true,
              hasPlan: true,
              inspectionCost:
                row.inspecitonDetailClassValue === INSPECTION_DETAIL_BOTH ||
                row.inspecitonDetailClassValue === INSPECTION_DETAIL_INSPECTION
                  ? row.doneInspectionCost
                  : 0,
              withPartsCost: true,
              partsCost:
                row.inspecitonDetailClassValue === INSPECTION_DETAIL_BOTH ||
                row.inspecitonDetailClassValue === INSPECTION_DETAIL_PARTS
                  ? (row.doneTotalWorkCost ?? 0) +
                    (row.doneTotalWorkCostWeekOut ?? 0) +
                    (row.doneTotalWorkCostHol ?? 0) +
                    (row.doneTotalWorkCostHolOut ?? 0) +
                    (row.doneTotalWorkCostForeign ?? 0) +
                    (row.donePartsCost ?? 0)
                  : 0,
              otherCost: row.doneExpenses,
              orderNo: row.orderNo,
              compQuotationFlg: row.compQuotationFlg,
              orderCreateUserId: row.orderCreateUserId,
            };
          });

        setVesselProduct(vesselProductData);
        setData(() => {
          const tmp = maintenaceScheduleData
            .filter((it) => true)
            .filter((it) =>
              (props.yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).includes(
                it.timing
              )
            );
          // 不足している年のデータを追加
          (props.yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).forEach(
            (timing) => {
              const existData = tmp.find((it) => {
                return it.timing === timing;
              });
              if (existData) {
                return;
              } else {
                const partsCosts = () => {
                  // 対象年度における交換推奨金額の合計を返す
                  let cost = 0;
                  vesselProductData.forEach((vesselProduct) => {
                    const vesselProductDataSub =
                      vesselProduct.vesselProductsGetResponseSub.filter(
                        (sub) =>
                          Number(sub.inspectionYearClassValue) === timing ||
                          Number(sub.inspectionYearClassValue) === timing + 1
                      );
                    const preData = vesselProductDataSub.find(
                      (p) => Number(p.inspectionYearClassValue) === timing
                    );
                    const afterData = vesselProductDataSub.find(
                      (a) => Number(a.inspectionYearClassValue) === timing + 1
                    );

                    if (
                      props.yearCheckValue === 0 &&
                      TIMING_SUBS.includes(timing)
                    ) {
                      // 特定の年度については後の年度の推奨内容も考慮
                      if (
                        preData &&
                        (preData.inspectionYearFlg ||
                          (afterData && afterData.inspectionYearFlg))
                      ) {
                        cost += preData.recommendDetailSubTotal ?? 0;
                      }
                    } else {
                      if (preData && preData.inspectionYearFlg) {
                        cost += preData.recommendDetailSubTotal ?? 0;
                      }
                    }
                  });
                  return cost;
                };

                tmp.push({
                  timing: timing,
                  isDone: false,
                  hasPlan: true,
                  inspectionCost: INSPECTION_COST,
                  withPartsCost: true,
                  partsCost: partsCosts(),
                  otherCost: OTHER_COST,
                  orderNo: undefined,
                  compQuotationFlg: false,
                  orderCreateUserId: undefined,
                });
              }
            }
          );
          return tmp;
        });
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E073" });
        }
      });
  }

  // 表示グラフチェックボックスクリック
  function onClickShowGraphButton(status: number) {
    if (status === 0) {
      setIsLineChart(false);
    } else {
      setIsLineChart(true);
    }
    setSelectedStatus(status);
  }

  // 定期点検・部品代トグルの変更による再計算
  function calcCausedToggle(
    dataRow: DataType,
    rowType: RowTypes,
    checked: boolean
  ) {
    if (rowType === RowTypes.InspectionCost) {
      dataRow.hasPlan = checked;
    } else {
      dataRow.withPartsCost = checked;
    }
    // 経費
    if (dataRow.hasPlan || dataRow.withPartsCost) {
      dataRow.otherCost = OTHER_COST;
    } else {
      dataRow.otherCost = 0;
    }
    // stateに保存しなおす
    const newData = data.map((it) => {
      if (it.timing === dataRow.timing) {
        return dataRow;
      } else {
        return it;
      }
    });
    setData(newData);
  }

  function cellRenderSelector(params: ICellRendererParams) {
    switch (params.data.rowType) {
      case RowTypes.IsDone:
        return { component: isDoneCell };
      case RowTypes.InspectionCost:
      case RowTypes.PartsCost:
        return { component: toggleValueCell };
      case RowTypes.Summary:
        return { component: summanyRowCell };
      default:
        return { component: costCell };
    }
  }

  function getDataRow(params: ICellRendererParams) {
    const timingString = params.column?.getColId().replace("col", "") ?? "0";
    return data.find((it) => it.timing.toString() === timingString)!;
  }

  // 実施状況行のセル定義
  function isDoneCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    const isDone = dataRow?.isDone ?? false;
    return (
      <>
        {dataRow && (
          <div className="text-center">
            {isDone && (
              <span
                style={{ fontSize: "14px", color: "red" }}
                data-cy={dataRow.timing + "年目実施状況"}
              >
                {tc("実施")}
              </span>
            )}
            {!isDone && (
              <span
                style={{ fontWeight: "bold" }}
                data-cy={dataRow.timing + "年目実施状況"}
              >
                -
              </span>
            )}
          </div>
        )}
      </>
    );
  }
  // 定期点検・部品代行のセル定義
  function toggleValueCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    const rowType = params.data?.rowType;
    const cost =
      rowType === RowTypes.InspectionCost
        ? dataRow?.inspectionCost
        : dataRow?.partsCost;
    let text = "";
    if (cost > 0) {
      text = cost.toLocaleString();
    }
    const checked =
      rowType === RowTypes.InspectionCost
        ? dataRow?.hasPlan
        : dataRow?.withPartsCost;
    return (
      <>
        {dataRow && (
          <div
            style={
              !dataRow.isDone
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }
                : {}
            }
          >
            {dataRow.isDone && <div style={{ height: "10px" }}></div>}
            {!dataRow.isDone && (
              <div
                className="form-check form-switch"
                style={{ minHeight: "0", height: "10px" }}
              >
                <input
                  className="form-check-input form-check-input-blue"
                  type="checkbox"
                  disabled={dataRow.isDone}
                  defaultChecked={checked}
                  onChange={(e) => {
                    calcCausedToggle(dataRow, rowType, e.target.checked);
                  }}
                  data-cy={
                    dataRow.timing +
                    "年目" +
                    (rowType === RowTypes.InspectionCost
                      ? "定期点検"
                      : "部品代") +
                    "チェックボックス"
                  }
                />
              </div>
            )}
            <div
              style={checked ? {} : { color: "lightgray" }}
              data-cy={
                dataRow.timing +
                "年目" +
                (rowType === RowTypes.InspectionCost ? "定期点検" : "部品代")
              }
            >
              {rowType === RowTypes.PartsCost && checked && (
                <a
                  className="text-link"
                  href="#"
                  onClick={(e) =>
                    partsReplaceListRef.current?.show(
                      company,
                      vessel,
                      dataRow.timing,
                      dataRow.isDone,
                      vesselProduct,
                      props.yearCheckValue
                    )
                  }
                >
                  {text}
                </a>
              )}
              {rowType === RowTypes.PartsCost && !checked && text}
              {rowType !== RowTypes.PartsCost && text}
            </div>
          </div>
        )}
      </>
    );
  }
  // 一般的な金額のセル定義
  function costCell(params: ICellRendererParams<GridType>) {
    const dataRow = getDataRow(params);
    const rowType = params.data?.rowType;
    let value = 0;
    switch (rowType) {
      case RowTypes.InspectionCost:
        value = dataRow?.inspectionCost;
        break;
      case RowTypes.PartsCost:
        value = dataRow?.partsCost;
        break;
      case RowTypes.OtherCost:
        value = dataRow?.otherCost;
        break;
    }

    return (
      <>{dataRow && <div>{value > 0 ? value.toLocaleString() : ""}</div>}</>
    );
  }
  // 合計行のセル定義
  function summanyRowCell(params: ICellRendererParams) {
    const dataRow = getDataRow(params);
    const value = dataRow ? calcSum(dataRow) : undefined;
    const compFlag =
      dataRow?.compQuotationFlg &&
      dataRow?.orderCreateUserId &&
      dataRow?.orderCreateUserId !== -1000 &&
      dataRow?.orderNo;
    return (
      <>
        <span
          style={{
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          <div>
            {compFlag && (
              <a
                className="text-link"
                href="#"
                onClick={(e) =>
                  handleClickOutputCompQuotationPdf(dataRow?.orderNo)
                }
              >
                {value?.toLocaleString()}
              </a>
            )}
            {!compFlag && value?.toLocaleString()}
          </div>
        </span>
      </>
    );
  }
  // 合計列のセル定義
  function summanyColumnCell(params: ICellRendererParams) {
    const rowType = params.data?.rowType;
    if (rowType === RowTypes.IsDone) {
      return <></>;
    }

    let inspectionCostSum = 0;
    let partsCostSum = 0;
    let otherCostSum = 0;

    data.forEach((it) => {
      if (it.hasPlan) {
        inspectionCostSum += it.inspectionCost;
      }
      if (it.withPartsCost) {
        partsCostSum += it.partsCost;
      }
      otherCostSum += it.otherCost;
    });
    const totalSum = inspectionCostSum + partsCostSum + otherCostSum;

    let value = 0;
    switch (rowType) {
      case RowTypes.InspectionCost:
        value = inspectionCostSum;
        break;
      case RowTypes.PartsCost:
        value = partsCostSum;
        break;
      case RowTypes.OtherCost:
        value = otherCostSum;
        break;
      case RowTypes.Summary:
        value = totalSum;
        break;
    }
    if (rowType === RowTypes.Summary) {
      return (
        <>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {value.toLocaleString()}
          </span>
        </>
      );
    } else {
      return <>{value.toLocaleString()}</>;
    }
  }

  // 完工見積書発行用リンククリック
  function handleClickOutputCompQuotationPdf(orderNo: number | undefined) {
    // 保存してなかったらエラー
    if (orderNo) {
      api
        .get(`/api/v1/comp-quotation-pdf/${orderNo}`, {
          responseType: "blob",
        })
        .then((it) => {
          const pdf = new Blob([it.data], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdf);
          window.open(pdfURL);
        })
        .catch((error) => {
          showDialog({ error });
        });
    }
  }

  // グリッドの列定義
  const columnDefs: ColDef[] = [
    {
      headerName: tc("内訳"),
      field: "rowTitle",
      width: isEnglish() ? 146 : 95,
      cellClassRules: {
        "b-cell-backgroupd-color-gray": (params: any) =>
          [
            RowTypes.IsDone,
            RowTypes.OtherCost,
            RowTypes.InspectionCost,
            RowTypes.PartsCost,
          ].includes(params.data.rowType),
        "b-cell-backgroupd-color-blue": (params: any) =>
          [RowTypes.Summary].includes(params.data.rowType),
      },
      cellClass: "b-cell-border-right-solid-thin-gray b-grid-cell-multi-line",
      cellStyle: { fontSize: "14px", fontWeight: "bold" },
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    },
  ];
  // 30年分の列を追加
  (yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).forEach((it) => {
    columnDefs.push({
      headerName:
        yearCheckValue === 0
          ? (TIMING_SUBS.includes(it) ? it + 0.5 : it) + tc("年")
          : it + tc("年"),
      cellRendererSelector: cellRenderSelector,
      cellClassRules: {
        "text-center": (params) =>
          [
            RowTypes.OtherCost,
            RowTypes.Summary,
            RowTypes.InspectionCost,
            RowTypes.PartsCost,
          ].includes(params.data.rowType),
        "b-cell-backgroupd-color-blue": (params: any) =>
          [RowTypes.Summary].includes(params.data.rowType),
      },
      cellClass: "b-cell-border-right-solid-thin-gray px-0",
      cellStyle: {
        fontSize: "12px",
      },
      colId: "col" + it,
      width: 95,
      headerClass: [
        "b-header-cell-center",
        "b-cell-border-right-solid-thin-gray",
      ],
    });
  });
  // 合計列を追加
  columnDefs.push({
    headerName: tc("合計"),
    cellRenderer: summanyColumnCell,
    colId: "colSum",
    cellClassRules: {
      "text-end": (params) =>
        [
          RowTypes.OtherCost,
          RowTypes.Summary,
          RowTypes.InspectionCost,
          RowTypes.PartsCost,
        ].includes(params.data.rowType),
      "b-cell-backgroupd-color-blue": (params: any) =>
        [RowTypes.Summary].includes(params.data.rowType),
    },
    cellClass: "b-cell-border-right-solid-thin-gray",
    cellStyle: { verticalAlign: "middle" },
    width: 140,
    headerClass: [
      "b-header-cell-center",
      "b-cell-border-right-solid-thin-gray",
    ],
    resizable: true,
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const doneColor = "#3778F4";
  const yetColor = "#A9A9B1";

  const graphElementColor = () => {
    return data.map((it) => (it.isDone ? doneColor : yetColor));
  };

  function calcSum(dataRow: DataType) {
    let value = 0;
    if (dataRow.hasPlan) {
      value += dataRow.inspectionCost;
    }
    if (dataRow.withPartsCost) {
      value += dataRow.partsCost;
    }

    return value + dataRow.otherCost;
  }
  const graphLables = (yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).map((it) =>
    yearCheckValue === 0
      ? (TIMING_SUBS.includes(it) ? it + 0.5 : it) + tc("年")
      : it + tc("年")
  );
  const graphValues = () => {
    return data
      .sort((a, b) => Number(a.timing) - Number(b.timing))
      .map((it) => calcSum(it));
  };

  type DatalabelAnchorType = "start" | "center" | "end";
  type DatalabelAlignType =
    | "center"
    | "start"
    | "end"
    | "right"
    | "bottom"
    | "left"
    | "top";

  const barGraphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: 0.4,
    scales: {
      y: {
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end" as DatalabelAnchorType,
        align: "center" as DatalabelAlignType,
        formatter: (value: number) => {
          return value.toLocaleString();
        },
        color: "#000",
        font: {
          size: 12,
        },
      },
    },
  };

  const barGpraphData = () => {
    return {
      labels: graphLables,
      datasets: [
        {
          label: tc("合計"),
          data: graphValues(),
          backgroundColor: graphElementColor(),
        },
      ],
    };
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        align: "right" as DatalabelAlignType,
        formatter: (value: number) => {
          return value.toLocaleString();
        },
        color: "#000",
        font: {
          size: 12,
        },
      },
    },
  };

  const lineChartData = () => {
    return {
      labels: ["", ...graphLables, ""],
      datasets: [
        {
          label: tc("合計"),
          data: [undefined, ...graphValues()],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: [undefined, ...graphElementColor()],
          pointBorderColor: [undefined, ...graphElementColor()],
        },
      ],
    };
  };

  // グラフの幅・位置調整
  const graphDivStyle = isLineChart
    ? {
        marginLeft: "50px",
        width: yearCheckValue === 0 ? "1240px" : "2950px",
      }
    : {
        marginLeft: isEnglish() ? "146px" : "96px",
        width: yearCheckValue === 0 ? "1150px" : "2850px",
      };

  // レンダリング
  return (
    <div className="h-100 scrollBar">
      <div style={{ marginTop: "24px", marginBottom: "10px" }}>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {tc("コスト推移")}
          <TooltipIcon>
            <div>
              <span style={{ margin: "0.5em 0px" }}>
                {tc("・ 実施状況：")}
                <span style={{ marginLeft: "0.5em" }}>
                  {tc("実施済⇒点検実施済み")}
                </span>
                <br />
                <span style={{ marginLeft: "6.4em" }}>
                  {tc('"ー"　 ⇒点検未実施')}
                </span>
              </span>
              <br />
              <br />
              <span style={{ margin: "0.5em 0px" }}>
                {tc("・ 定期点検：")}
                <span style={{ marginLeft: "0.5em" }}>
                  {tc("実施済⇒確定金額")}
                </span>
                <br />
                <span style={{ marginLeft: "6.3em" }}>
                  {tc("未実施⇒80万円(想定値)")}
                </span>
              </span>
              <br />
              <br />
              <span style={{ margin: "0.5em 0px" }}>
                {tc("・ 部品代　：")}
                <span style={{ marginLeft: "0.5em" }}>
                  {tc("実施済⇒実施合計金額")}
                </span>
                <br />
                <span style={{ marginLeft: "6.3em" }}>
                  {tc("未実施⇒当社交換推奨金額(想定値)")}
                </span>
              </span>
              <br />
              <br />
              <span style={{ margin: "0.5em 0px" }}>
                {tc("・ 経費　　：")}
                <span style={{ marginLeft: "0.5em" }}>
                  {tc("実施済⇒実経費金額")}
                </span>
                <br />
                <span style={{ marginLeft: "6.3em" }}>
                  {tc("未実施⇒15万円(想定値)")}
                </span>
              </span>
            </div>
          </TooltipIcon>
        </span>
      </div>
      <div
        style={{
          marginTop: "10px",
          backgroundColor: "white",
          borderRadius: "15px",
          width: yearCheckValue === 0 ? "1460px" : "3120px",
        }}
      >
        <div
          className={"ag-theme-alpine b-ag-deleted-row b-header-row-white"}
          style={{
            padding: "6px",
            height: "272px",
            width: yearCheckValue === 0 ? "1425px" : "3085px",
          }}
          data-cy="コスト推移グリッド"
        >
          <AgGridReact
            domLayout="normal"
            columnDefs={columnDefs}
            rowData={gridData}
            suppressHorizontalScroll
          />
        </div>
        <div className="w-100 mx-auto my-1 ps-2">
          {tc("表示グラフ")}
          <br />
          <div>
            {showGraphList.map((it) => {
              return (
                <input
                  key={it.value}
                  type="button"
                  className={`btn me-2 rounded-pill`}
                  style={
                    it.value === selectedShowGraph
                      ? selectGraphStyle
                      : notSelectGraphStyle
                  }
                  value={it.text}
                  onClick={(e) => onClickShowGraphButton(it.value)}
                  data-cy={
                    it.value === 0 ? "棒グラフボタン" : "折れ線グラフボタン"
                  }
                />
              );
            })}
          </div>
        </div>
        <div
          style={{
            height: "290px",
            ...graphDivStyle,
          }}
          data-cy="コスト推移グラフ"
        >
          {!isLineChart && (
            <Bar options={barGraphOptions} data={barGpraphData()} />
          )}
          {isLineChart && (
            <Line options={lineChartOptions} data={lineChartData()} />
          )}
        </div>
      </div>
      <PartsReplaceList ref={partsReplaceListRef} />
    </div>
  );
}

export default RepairCostSimulation;
